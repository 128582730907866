import classes from './WebsiteControl.module.scss'

import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from '../../store/ui-slice';

import excel_icon from '../../assets/icons/excel.svg'

import WebsitesControlTableItem from './WebsiteControlTableItem/WebsiteControlTableItem';
import PortalsTableItem from './PortalsTableItem/PortalsTableItem';
import UpdatePortalStock from './UpdatePortalStock/UpdatePortalStock';

import CustomButton from "../Customs/CustomButton";
import Pagination from '../Pagination/Pagination';
import HeadersAllWebsites from './HeadersAllWebsites';
import HeadersOnlyPortal from './HeadersOnlyPortal';
import AddNewDomain from '../AddNewDomain/AddNewDomain';
import SearchBar from '../SearchBar/SearchBar';
import AmountSelect from '../AmountSelect/AmountSelect';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import CustomSelect from '../Customs/CustomSelect/CustomSelect';
import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium';

import { CSVLink } from "react-csv";

import WebsiteStatusHistory from '../WebsiteStatusHistory/WebsiteStatusHistory';
import WebsiteSettings from '../WebsiteSettings/WebsiteSettings';
import { clearAllTimeouts, TimeOutHandler, statusTranslator, fullYearParser ,hoursMinutesParser, websiteControlCSVHandler } from '../../utils/helper_functions';

const WebsiteControl = () => {

    const names = useSelector(state => state.ui.language.values)
    const language = useSelector(state => state.ui.language.code)
    const headers_names = names.websitesContent.headers
    const api_token = useSelector(state => state.user.token)
    const isAdmin = useSelector(state => state.user.isAdmin)

    const disableExport = useSelector(state => state.ui.disableExportButton)
    const disableImport = useSelector(state => state.ui.disableImportButton)

    const websiteSearchParam = window.location.search.split('=')[1]
    
    const [loadingResults, setLoadingResults] = useState(true)
    const [Status, setStatus] = useState('all')
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(20)
    const [numberOfPages, setNumberOfPages] = useState(10)
    const numberOfEntries = useRef(0)

    const [Data, setData] = useState([])
    const [portalData, setPortalData] = useState([])

    const [websitesNumbers, setWebsitesNumbers] = useState({
        customer_active: 0,
        company_active: 0,
        company_inactive: 0,
        on_pause: 0,
        inactive: 0,
        payment_issue: 0,
        canceling: 0,
        black_list: 0,
        firstTime: true
    })
    const [searchResults, setSearchResults] = useState(websiteSearchParam ? websiteSearchParam : '')
    const [portalSearch, setPortalSerach] = useState('')
    const [toggleWebsitesPortal, setToggleWebsitesPortal] = useState(false)

    const [showAddNewDomain, setShowAddNewDomain] = useState(false)
    const [showWebsiteHistory, setShowWebsiteHistory] = useState(false)
    const [showWebsiteSettings, setShowWebsiteSettings] = useState(false)
    const [showUpdatePortalStocks, setShowUpdatePortalStocks] = useState(false)

    const [websiteHistory, setWebsiteHistory] = useState([])
    const [websiteSettingsData, setWebsiteSettingsData] = useState({})

    const [selectedPortal, setSelectedPortal] = useState({})

    const API_URL = process.env.REACT_APP_API_URL
    const dispatch = useDispatch()

    let csv_name = new Date()
    csv_name = `${fullYearParser(csv_name)}-${hoursMinutesParser(csv_name)}`

    useEffect(async() => {
        await importWebsitesNumbers()
    },[])

    useEffect(async () => {

        if(searchResults.length === 0) {
            setLoadingResults(true)
            await importWebsiteData()
            setLoadingResults(false)
        }
        else {

            clearAllTimeouts()

            TimeOutHandler(async() => {
                setLoadingResults(true)
                await importWebsiteData()
                setLoadingResults(false)
            },600)
        }

    },[pageNumber,numberOfResults,searchResults,Status])


    const importWebsiteData = async () => {
        const response = await fetch(`${API_URL}/api/v2/users/websites/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                items: numberOfResults,
                page: pageNumber,
                search: searchResults,
                status: Status === 'all' ? undefined : Status
            })

        })

        const data = await response.json()

        if(data.result) {
            setNumberOfPages(Math.ceil(data.entries/numberOfResults))
            numberOfEntries.current = data.entries
            setData(data.result)
        }

    }

    const importWebsitesNumbers = async () => {

        let REQ_URL = `${API_URL}/api/v2/users/websites/list`
        let req_headers = {'Content-Type': 'application/json','authorization': `Bearer ${api_token}`}

        let customer_active = await fetch(REQ_URL, {method:'POST', headers: req_headers, body: JSON.stringify({items: 1,page: 1,status: 'CUSTOMER_ACTIVE'})})
        customer_active = await customer_active.json()

        let company_active = await fetch(REQ_URL, {method:'POST', headers: req_headers, body: JSON.stringify({items: 1,page: 1,status: 'COMPANY_ACTIVE'})})
        company_active = await company_active.json()

        let company_inactive = await fetch(REQ_URL, {method:'POST', headers: req_headers, body: JSON.stringify({items: 1,page: 1,status: 'COMPANY_INACTIVE'})})
        company_inactive = await company_inactive.json()

        let on_pause = await fetch(REQ_URL, {method:'POST', headers: req_headers, body: JSON.stringify({items: 1,page: 1,status: 'ON_PAUSE'})})
        on_pause = await on_pause.json()

        let inactive = await fetch(REQ_URL, {method:'POST', headers: req_headers, body: JSON.stringify({items: 1,page: 1,status: 'INACTIVE'})})
        inactive = await inactive.json()

        let payment_issue = await fetch(REQ_URL, {method:'POST', headers: req_headers, body: JSON.stringify({items: 1,page: 1,status: 'PAYMENT_ISSUE'})})
        payment_issue = await payment_issue.json()

        let canceling = await fetch(REQ_URL, {method:'POST', headers: req_headers, body: JSON.stringify({items: 1,page: 1,status: 'CANCELING'})})
        canceling = await canceling.json()

        let blacklist = await fetch(REQ_URL, {method:'POST', headers: req_headers, body: JSON.stringify({items: 1,page: 1,status: 'BLACK_LIST'})})
        blacklist = await blacklist.json()

        setWebsitesNumbers({
            customer_active: customer_active.entries,
            company_active: company_active.entries,
            company_inactive: company_inactive.entries,
            on_pause: on_pause.entries,
            inactive: inactive.entries,
            payment_issue: payment_issue.entries,
            canceling: canceling.entries,
            black_list: blacklist.entries
        })

    }

    const portalUsersHandler = async () => {
        setLoadingResults(true)
        setToggleWebsitesPortal(true)

        const response = await fetch(`${API_URL}/api/v2/users/websites/portals`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                customerID: ''
            })

        })

        const data = await response.json()

        setPortalData(data.result)
        setPageNumber(1)
        setLoadingResults(false)
    }

    const portalDataFilter = (item) => {
        return item.firstName.includes(portalSearch) || item.lastName.includes(portalSearch) || item.email.includes(portalSearch)
    }

    const openWebsiteHandler = async () => {
        setToggleWebsitesPortal(false)
        setLoadingResults(true)
        await importWebsiteData()
        setLoadingResults(false)
    }

    const addNewWebsiteHandler = () => {
        setShowAddNewDomain(val => !val)
    }

    const changeNumberofResultsHandler = (e) => {
        let value = parseInt(e.target.value)
        setPageNumber(1)
        setNumberOfResults(value)
    }

    const importFromSpreadsheetHandler = async() => {

        let response = await fetch(`${API_URL}/api/v2/employees/import`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`}
        })
        let res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            dispatch(uiActions.setDisableImportButton(true))
            TimeOutHandler(() => uiActions.setDisableImportButton(false),300000)
        }

    }

    const exportFromSpreadsheetHandler = async() => {

        let response = await fetch(`${API_URL}/api/v2/employees/export`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`}
        })
        let res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            dispatch(uiActions.setDisableExportButton(true))
            TimeOutHandler(() => uiActions.setDisableExportButton(false),300000)
        }

    }

    const resetExportImportButtons = () => {
        dispatch(uiActions.setDisableImportButton(false))
        dispatch(uiActions.setDisableExportButton(false))
    }

    const toggleWebsiteStatusHistory = () => {setShowWebsiteHistory(val => !val)}

    const toggleWebsiteSettings = () => {setShowWebsiteSettings(val => !val)}

    const showWebsiteStatusHistoryHandler = (history) => {
        setWebsiteHistory(history)
        setShowWebsiteHistory(true)
    }

    const showWebsiteSettingsHandler = (data) => {
        setWebsiteSettingsData(data)
        setShowWebsiteSettings(true)
    }

    const showUpdatePortalStockHandler = (data) => {
        setSelectedPortal(data)
        setShowUpdatePortalStocks(true)
    }

    return (
        <>
        {showAddNewDomain && <AddNewDomain toggle={addNewWebsiteHandler}/>}
        {showWebsiteHistory && <WebsiteStatusHistory toggle={toggleWebsiteStatusHistory} data={websiteHistory}/>}
        {showWebsiteSettings && <WebsiteSettings toggle={toggleWebsiteSettings} data={websiteSettingsData}/>}
        {showUpdatePortalStocks && <UpdatePortalStock data={selectedPortal} toggle={() => setShowUpdatePortalStocks(false)} reloadData={portalUsersHandler}/>}
        <div className={classes.container}>

            <div className={classes.buttons}>
                <div>
                    {toggleWebsitesPortal && <CustomButton name={headers_names.show_websites} reverse color='blue' onClick={openWebsiteHandler}/>}
                    {!toggleWebsitesPortal && <CustomButton name={headers_names.show_portal} reverse color='blue' onClick={portalUsersHandler}/>}
                </div>
                {isAdmin &&
                <div className={classes.buttons_right}>
                    <CustomButton name={headers_names.import} onClick={importFromSpreadsheetHandler} color='red' disabled={disableImport}/>
                    <CustomButton name={headers_names.export} onClick={exportFromSpreadsheetHandler} color='blue' disabled={disableExport}/>
                    <CustomButton name={headers_names.reset} color='blue' reverse onClick={resetExportImportButtons}/>
                </div>
                }
            </div>

            <div className={classes.main}>

                <div className={classes.main_top}>
                    <div className={classes.main_title}>{headers_names.websites}</div>
                    <div style={{display: 'flex', gap: '2rem'}}>
                        {!toggleWebsitesPortal &&
                            <CustomSelect value={Status} onChange={(e) => setStatus(e.target.value)}>
                                <option value={'all'}>{statusTranslator('all', language)}</option>
                                <option value={'CUSTOMER_ACTIVE'}>{statusTranslator('Customer Active', language)}</option>
                                <option value={'COMPANY_ACTIVE'}>{statusTranslator('Company Active', language)}</option>
                                <option value={'COMPANY_INACTIVE'}>{statusTranslator('Company Inactive', language)}</option>
                                <option value={'ON_PAUSE'}>{statusTranslator('On Pause', language)}</option>
                                <option value={'INACTIVE'}>{statusTranslator('Inactive', language)}</option>
                                <option value={'PAYMENT_ISSUE'}>{statusTranslator('Payment Issue', language)}</option>
                                <option value={'CANCELING'}>{statusTranslator('Canceling',language)}</option>
                                <option value={'BLACK_LIST'}>{statusTranslator('Black List', language)}</option>
                            </CustomSelect>                        
                        }
                        <CSVLink data={websiteControlCSVHandler(Data,language)} filename={`${csv_name}.csv`}>
                            <IconContainerMedium image_src={excel_icon} image_alt='excel' color='green'/>
                        </CSVLink>
                        {isAdmin && <CustomButton name={headers_names.new_domain} onClick={addNewWebsiteHandler} reverse color='red'/>}
                    </div>
                </div>

                <div className={classes.main_middle}>
                     {!toggleWebsitesPortal && <SearchBar placeholder={headers_names.search_domain} value={searchResults} onChange={(e) => setSearchResults(e.target.value)}/>}
                     {toggleWebsitesPortal && <SearchBar placeholder={headers_names.search_portal} value={portalSearch} onChange={(e) => setPortalSerach(e.target.value)}/>}
                    {!toggleWebsitesPortal && 
                    <div className={classes.amountselect}>
                        <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={changeNumberofResultsHandler}/>
                    </div>                    
                    }

                    <div className={classes.website_details_container}>
                        <span>{`customer_active: ${websitesNumbers.customer_active}`}</span>
                        <span>{`company_active: ${websitesNumbers.company_active}`}</span>
                        <span>{`company_inactive: ${websitesNumbers.company_inactive}`}</span>
                        <span>{`on_pause: ${websitesNumbers.on_pause}`}</span>
                        <span>{`inactive: ${websitesNumbers.inactive}`}</span>
                        <span>{`payment_issue: ${websitesNumbers.payment_issue}`}</span>
                        <span>{`canceling: ${websitesNumbers.canceling}`}</span>
                        <span>{`black_list: ${websitesNumbers.black_list}`}</span>
                    </div>
                </div>

                <ResponsiveTable loadingResults={loadingResults}>
                    <thead>
                        {!toggleWebsitesPortal && <HeadersAllWebsites names={headers_names}/>}
                        {toggleWebsitesPortal && <HeadersOnlyPortal names={headers_names}/>}
                    </thead>
                    <tbody className={classes.table_data}>
                        {!toggleWebsitesPortal && Data.map((item,index) => <WebsitesControlTableItem data={item} key={index} portalState={toggleWebsitesPortal} HistoryHandler={() => showWebsiteStatusHistoryHandler(item.statusHistory)} SettingsHandler={() => showWebsiteSettingsHandler(item)}/>)}  
                        {toggleWebsitesPortal && portalData.filter((item) => portalDataFilter(item)).map((item,index) => <PortalsTableItem data={item} key={index} onStocksClick={showUpdatePortalStockHandler}/>)}
                    </tbody>
                </ResponsiveTable>

                {!toggleWebsitesPortal &&
                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={changeNumberofResultsHandler}/>
                    {numberOfPages > 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>
                }
            </div>
        </div>
        </>
    )
}

export default WebsiteControl;
