import classes from './WebsiteSetting.module.scss'

import CustomButton from '../Customs/CustomButton';
import CheckAnimation from '../CheckAnimation/CheckAnimation';
import FailAnimation from '../FailAnimation/FailAnimation';
import PopUpCard from '../Cards/PopUpCard/PopUpCard';

import { fullYearParser } from '../../utils/helper_functions';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const WebsiteSettings = (props) => {

    let start_service = undefined;

    if(props.data.startService) {
    let startservice_temp = new Date(props.data.startService*1000)
    let removeSlash = new RegExp('/','g')
    start_service = fullYearParser(startservice_temp).replace(removeSlash,'-').split('-').reverse().join('-')
    }

    const [domain, SetDomain] = useState(props.data.domain)
    const [viewID, setViewID] = useState(props.data.analytics.viewID)
    const [trackingID, setTrackingID] = useState(props.data.analytics.trackingID)
    const [retainer, setRetainer] = useState(props.data.retainer)
    const [serviceStart, setServiceStart] = useState(start_service)

    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)

    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL

    const submitHandler = async (e) => {
        e.preventDefault()

        let response = await fetch(`${API_URL}/api/v2/employees/websites`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                websiteID: props.data.websiteID,
                domain: domain === props.data.domain ? undefined : domain,
                startService: serviceStart === start_service ? undefined : serviceStart,
                trackingID: trackingID === props.data.analytics.trackingID ? undefined : trackingID,
                viewID: viewID === props.data.analytics.viewID ? undefined : viewID,
                retainer: retainer === props.data.retainer ? undefined : retainer

            })
        })
        const res_status = response.status
        response = await response.json()

        if(res_status===200) {
            setShowCheckAnimation(true)
        }
        else {
            setShowFailAnimation(true)
            setTimeout(() => {
                setShowFailAnimation(false)
            }, 3000);
        }
    }

    return (
        <PopUpCard toggle={props.toggle} header={'Website Settings'}>
                {showCheckAnimation && <CheckAnimation />}
                {showFailAnimation && <FailAnimation />}
                <form className={classes.main_middle} onSubmit={submitHandler}>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'Domain'}</label>
                    <div className={classes.middle_item_input}>
                        <input className={classes.input} value={domain} onChange={(e) => SetDomain(e.target.value)}/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'View ID'}</label>
                    <div className={classes.middle_item_input}>
                        <input className={classes.input} value={viewID} onChange={(e) => setViewID(e.target.value)}/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'Tracking ID'}</label>
                    <div className={classes.middle_item_input}>
                        <input className={classes.input} value={trackingID} onChange={(e) => setTrackingID(e.target.value)}/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'Retainer'}</label>
                    <div className={classes.middle_item_input}>
                        <input className={classes.input} type='number' value={retainer} onChange={(e) => setRetainer(e.target.value)}/>
                    </div>
                </div>

                    <div className={classes.middle_item}>
                        <label className={classes.middle_item_label}>{'Service Start'}</label>
                        <div className={classes.middle_item_input}>
                            <input className={classes.dateinput} type='date' value={serviceStart} onChange={(e) => setServiceStart(e.target.value)}/>
                        </div>
                    </div>

                <div className={classes.buttons}>
                    <div className={classes.cancel} onClick={props.toggle}>Cancel</div>
                    <div className={classes.customButton}><CustomButton reverse color='red' name='Submit' type='submit'/></div>
                </div>
                </form>
        </PopUpCard>
    )

}

export default WebsiteSettings;