const NAMES = {
    ENG: {
        total: 'Total',
        status: 'Status',
        revenue: 'Revenue',
        notPaid: 'Balance',
        withdrawn: 'Withdrawn',
        nothingToShow: 'NOTHING TO SHOW',
        emptyGraph: 'Here will be graph with website earnings, when you will have at least one website',
        emptyTotals: 'Here will be graph with total income by months',
        logout: 'Log out',
        websitesContent: {
            websites: 'Websites',
            orientation: 'Please switch your device to album orientation',
            newDomain: 'Add new domain',
            search: 'Search by domain',
            headers: {
                domain: 'Domain',
                added: 'added',
                type: 'type',
                status: 'status',
                websiteID: 'websiteID',
                owned: 'Start service',
                earnedNIS: '₪ earned',
                earnedUSD: '$ earned ',
                retainer: 'retainer',
                customer: 'customer',
                show_portal: 'Show Portal Users',
                show_websites: 'Show Websites',
                new_domain: 'Add New Domain',
                import: 'Import From Spreadsheet',
                export: 'Export From Spreadsheet',
                reset: 'Reset',
                websites: 'Websites',
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email',
                status: 'Status',
                units: 'Units',
                portal: 'Portal',
                search_domain: 'Search By Domain',
                search_portal: 'Search By Name Or Email'
            },
            customerWebsites: {
                owner_since: 'Owner Since'
            },
            onAir: {
                general_information: 'General information'
            },
            AddNewDomain: {
                title: 'Add New Domain',
                domain_name: 'Domain Name',
                select_website_type: 'Select portal or website',
                cancel: 'Cancel',
                add: 'Add Domain'
            },
            AddNewCampaign: {
                title: 'Add New Campagin',
                website_name: 'Website name',
                customer: 'Customer',
                date: 'Date',
                retainer: 'Retainer',
                cancel: 'Cancel',
                add: 'Add Campaign'
            },
            pagination: {
                showing: 'Showing',
                rows: 'Rows',
                page: 'Page',
                of: 'of'
            },
            payments: {
                total: 'Total',
                payments_requests: 'Payments Requests',
                new_payment_request: 'New Payment Request',
                new_payment_request_short: 'Request',
                send: 'send',
                send_request: 'Send Request',
                status: 'Request status',
                date: 'Date',
                type: 'Type',
                amount: 'Amount',
                receipt: 'Receipt',
                tax_invoice: 'Tax Invoice',
                receipt_status: 'Receipt status',
                receipt_tax: 'Receipt tax invoice',
                approved: 'Approved Date',
                client_name: 'Client Name',
                notes: 'Notes',
                charge_refund: 'Charge Refund',
                month: 'Month',
                year: 'Year',
                earnings: 'Earnings',
                notes: 'Notes',
                client_name: 'Client Name',
                client_check: 'Client Check',
                view: 'View',
                upload: 'Upload',
                not_available: 'Not Available',
                all_request: 'All Requests',
                processing: 'Processing',
                request_accepted: 'Request Accepted',
                request_denied: 'Requed Denied',
                payment_process: 'Payment Process',
                paid: 'Paid',
                updateStatus: {
                    title: 'Update Status for',
                    approve: 'Approve',
                    reject: 'Reject',
                    none: 'Not Decided',
                    submit: 'Submit',
                    cancel: 'Cancel'
                },
                approveReject: {
                    title: 'Change Status for Payment',
                    approve: 'Request Accepted',
                    reject: 'Request Declined',
                    process: 'Processing',
                    payment_process: 'Payment Process'
                }
            },
            dragAndDrop: {
                receipt: 'Upload Receipt File',
                invoice: 'Upload Tax Invoice File',
                submit: 'Send Request',
                retry: 'Try Again'
              },
            status: {
                INACTIVE: 'Inactive',
                COMPANY_ACTIVE: 'Company active',
                PAYMENT_ISSUE: 'Payment issue',
                CUSTOMER_ACTIVE: 'Customer active',
                ON_PAUSE: 'On pause',
                CANCELING: 'Cancelling',
                ALL: 'All',
                COMPANY_INACTIVE: 'Company Inactive',
                BLACK_LIST: "Black list"
            }
        },
        transfersContent: {
            transfers: 'Transactions',
            transactions: 'Transcations',
            date_range: 'Date Range',
            start_date: 'Select Start Date',
            end_date: 'Select End Date',
            cancel: 'Cancel',
            load: 'Load Data',
            added: 'Added',
            count: 'Count',
            domain: 'Domain',
            actions: 'Actions',
            manage: 'Options',
            nisRate: 'Rate',
            date: 'Payment date',
            paid: 'Paid',
            type: 'Type',
            NIS: '₪ Amount',
            USD: '$ Amount',
            websiteID: 'Site ID',
            customerID: 'Client ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            name: 'Customer name',
            rate: '$ Rate',
            filterRange: 'Revenue for selected period',
            invalidDate: 'Invalid Date',
            paid: 'Sent For Payment',
            not_paid: 'Not Paid',
            select_website: 'Select Website:',
            view: 'View',
            show_stack: 'Show Stack',
            hide_stack: 'Return',
            buttons: {
                reset_filter: 'Reset Filter',
                date_filter: 'Filter By Date',
                trasnfers_calculator: 'Transfers Calculator',
                reset: 'Reset',
                mark_as_paid: 'Mark as Paid',
                mark_as_not_paid: 'Mark as Not Paid',
                filter: 'Filter',
                calculator: 'Calculator',
                submit: 'Submit',
                cancel: 'Cancel'
            }

        },
        adminTable: {
            customer: 'Customer',
            email: 'Email',
            websites: 'Websites',
            status: 'Status',
            revenue: 'Revenue',
            withdrawn: 'Withdrawn',
            last_transaction: 'Last Transaction',
            not_paid: 'Not paid',
            added: 'Added',
            last_login: "Last login",
            add_new_user: 'Add New User',
            title: 'Customers'
        },
        tableContent: {
            'REGULAR': 'Website',
            'SPONSORED_CONTENT': 'Sponsored content',
            'PORTAL': 'Portal',
            'BACKLINK': 'Backlink',
            'filter': 'Filter by date',
            'BUSY': 'Busy',
            'CONTRACT_REQUIRED': "Contract required",
            'Not Paid': 'Not Paid',
            'Paid': 'Sent for payment',
            'FREE': 'Free'

        },
        pages: {
            dashboard: 'Dashboard'
        },
        graphs: {
            incomeMonths: 'Income by months',
            webSiteIncome: 'Total income by website',
            dataType: 'Data for: ',
            lastMonths: 'last months',
            allTime: 'All time',
            analytics: 'Sessions for last 30 days'
        },
        sideBar: {
            newsletter: 'Newsletter',
            dashboard: 'Dashboard',
            websites: 'My Websites',
            transactions: 'Transactions',
            profile: 'My Profile',
            managing: 'Customers',
            requests: 'Payments'
        },
        sideBarAdmin: {
            newsletter: 'Newsletter',
            dashboard: 'Dashboard',
            websites: 'Website Control',
            transactions: 'Transactions',
            profile: 'My Profile',
            managing: 'Customers',
            messaging: 'Message Center',
            logs: 'Logs',
            onair: "On Air",
            payments: 'Payments',
            employees: "Employees",
            change_currency: 'Change currency rate'
        },
        navBar: {
            'Dashboard': 'Dashboard',
            'My Websites': 'Websites',
            'Transactions': 'Transactions',
            'My Profile': 'Profile',
            'Management': 'Customers',
            'Payments':'Payments',
            'Newsletter': 'Newsletter',
            'Logs': 'Logs',
            'On air': "On air",
            "Employees": "Employees"

        },
        profile: {
            changeMail: 'Change Email',
            changePassword: 'Change Password',
            updateBankAccount: 'Update Bank Account',
            affiliate_since: 'Affiliate since',

        },
        changePassword: {
            title: 'Change Password',
            current: 'Current Password',
            new: 'New Password',
            confirm: 'Confirmation',
            update: 'Update Password',
            cancel: 'Cancel'
        },
        changeEmail: {
            title: 'Change Email',
            current: 'Current Email',
            new: 'New Email',
            password: 'Password',
            update: 'Update Email',
            cancel: 'Cancel'
        },
        customersContent: {
            'Customers': 'Customers'
        },
        webSlider: {
            head: 'Analytics Setting',
            retainer: 'RETAINER',
            noRetainer: 'No Retainer',
            serviceStart: 'SERVICE START',
            buttons: {
                change_domain: 'Change domain',
                edit: 'Edit',
                change_vi: 'Change view ID',
                change_id: "Change ID",
                change_ti: "Change Tracking ID",
                change_retainer: 'Change Retainer',
                change_time: 'Change time',
                close: 'Close'
            },
            domainDialog: {
                enter_new: 'Enter new'
            }
        },
        newCustomer: {
            addNew: 'Add New Customer',
            editUser: 'Edit Customer',
            firstNameENG: "First Name (English)",
            companyNameENG: "Company Name (English)",
            lastNameENG: "Last Name (English)",
            firstNameHEB: "First Name Hebrew*",
            lastNameHEB: "Last Name (Hebrew)*",
            companyNameHEB: "Company Name (Hebrew)",
            cityENG: "City Name (English)",
            streetENG: "Street Name (English)",
            cityHEB: "City Name (Hebrew)",
            streetHEB: "Street Name (Hebrew)",
            phone: "Phone Number*",
            email: "Email Address*",
            password: "Password*",
            repeat: "Repeat Password*",
            cancel: 'Cancel',
            submit: 'Submit'
        },
        newTransfer: {
            create: 'Create New Transfer',
            show_stack: 'Show Stack',
            hide_stack: 'Hide Stack',
            type: 'Type',
            website: 'Website',
            count: 'Count',
            amount_usd: 'Amount USD',
            nis_rate: 'NIS Rate',
            date: 'Date',
            cancel: 'Cancel',
            add: 'Add To Stack',
            submit: 'Submit'
        },
        Admin: {
            search: 'Search by email',
            searchName: 'Search by name',
            byEmail: 'By email',
            byName: 'By name',
            activeUsers: 'Only Active users',
            inactiveUsers: 'Only Inactive users',
            allUsers: 'All users',
            active: 'Active',
            inactive: 'Inactive',
            all: 'All'
        },
        buttons: {
            submit: "Submit",
            cancel: "Cancel",
            active: "ACTIVE",
            inactive: "INACTIVE",
            edit: "EDIT",
            view: "VIEW"
        },
        logs: {
            title: 'Logs',
            impersonate: 'Impersonsate',
            customer_create: 'Customer Create',
            website_active: 'Website Status(Active)',
            website_others: 'Website Status(Others)',
            on_air: 'On Air',
            delete_on_air: 'Delete On Air',
            transfer: 'Transfer',
            delete_transfer: 'Delete Transfer',
            move_transfer: 'Move Transfer',
            headers: {
                date: 'Date',
                type: 'Type',
                responsible: 'Responsible',
                details: 'Details'
            },
            types: {
                website_status: 'Website status',
                transfer: 'New transfer',
                transfer_del: "Delete transfer",
                new_customer: "New customer",
                onair: 'On air',
                delete_onair: 'Delete On air',
                transfer_move: 'Move transfer',
                impersonate: 'Impersonate customer'
            },
            yesterday: 'Yesterday',
            today: 'Today'
        },
        campaigns: {
            new: 'Create new',
            wait: 'Just a moment...',
            done: 'Done',
            go: "Go",
            cancel: 'Cancel',
            startDate: 'Start date',
            error: 'Something went wrong',
            history: 'History',
            create_new: 'Create New',
            on_air: 'On Air',
            title: 'Campaigns',
            table: {
                confirmations: 'Confirmation status',
                added: 'Added',
                customer: 'Customer',
                website: 'Website',
                status: 'Line status',
                start: 'Start date',
                empty: "There's no campaigns at the moment",
                retainer: 'Future retainer',
                default: 'Default',
                additionalNote: "Note",
                delete: 'Delete',
                pending: {
                    wait: 'Waiting for approval',
                    ready: 'Ready to run in time'
                },
                confirmation: {
                    approve: 'Approve and continue',
                    decline: 'Decline',
                    reason: 'Reason',
                    h1: 'Please confirm or deny the following item',
                    summary: 'Short summary',
                    details: {
                        firstName: 'First name',
                        lastName: 'Last name',
                        email: 'Email',
                        website: 'Website'

                    }
                }
            }
        }
    },
    HEB: {
        total: 'סך הכל הכנסה',
        bbb: 'bbb',
        status: 'סטטוס',
        revenue: 'סך הכל הכנסות מאתרים',
        notPaid: 'סכום זמין למשיכה',
        withdrawn: 'סכום שעובר לתשלום',
        nothingToShow: 'אין מה להראות',
        emptyGraph: 'להלן תרשים עם רווחי האתר, כאשר יהיה לך אתר אחד לפחות',
        emptyTotals: 'להלן תרשים עם סך ההכנסות לפי חודשים',
        logout: "יציאה",
        websitesContent: {
            websites: 'אתרים',
            orientation: 'אנא העבר את המכשיר לכיוון האלבום',
            newDomain: 'הסוף אתר',
            search: 'חיפוש לפי דומיין',
            headers: {
                domain: 'דומיין',
                added: 'נוסף',
                type: 'נכס',
                status: 'סטטוס',
                websiteID: 'ID',
                owned: 'בבעלות',
                earned: 'הכנסות',
                earnedNIS: '₪ הכנסות',
                earnedUSD: '$ הכנסות',
                customer: 'לקוח',
                retainer: 'ריטיינר',
                show_portal: 'הצג משתמשי פורטל',
                show_websites: 'חזרה אחורה',
                new_domain: 'הוסף דומיין חדש',
                import: 'ייבא מספרדשיט',
                export: 'יצא מספרדשיט',
                reset: 'איפוס כפתורים',
                websites: 'ניהול אתרים',
                first_name: 'שם פרטי',
                last_name: 'שם משפחה',
                email: 'איימיל',
                status: 'סטטוס',
                units: 'יחידות',
                portal: 'פורטל',
                search_domain: 'חפש לפי דומיין',
                search_portal: 'חפש לפי שם או דוא"ל'
            },
            customerWebsites: {
                owner_since: 'בעלים מאז'
            },
            onAir: {
                general_information: 'מידע כללי'
            },
            AddNewDomain: {
                title: 'הוספת דומיין חדש',
                domain_name: 'כתובת האתר',
                select_website_type: 'תבחר סוג אתר',
                cancel: 'ביטול',
                add: 'הוסף דומיין'
            },
            AddNewCampaign: {
                title: 'הוספת קמפיינג חדש',
                website_name: 'כתובת האתר',
                customer: 'לקוח',
                date: 'תאריך',
                retainer: 'ריטיינר עתידי',
                cancel: 'ביטול',
                add: 'צור קמפיינג'
            },
            pagination: {
                showing: 'מציג',
                rows: 'שורות',
                page: 'עמוד',
                of: 'מתוך'
            },
            payments: {
                total: 'סה"כ',
                payments_requests: 'בקשות תשלום',
                new_payment_request: 'בקשת תשלום חדשה',
                new_payment_request_short: 'בקשה חדשה',
                send: 'שליחה',
                send_request: 'שלח בקשה',
                status: 'סטטוס בקשה',
                type: 'סוג',
                date: 'תאריך בקשה',
                amount: 'סכום',
                receipt: 'מסמך',
                tax_invoice: 'קבלה/חשבונית',
                receipt_status: 'אישור מסמך',
                receipt_tax: 'קבלה/חשבונית',
                approved: 'תאריך אישור',
                client_name: 'שם לקוח',
                notes: 'הערות',
                charge_refund: 'חיוב זיכוי',
                month: 'חודש',
                year: 'שנה',
                earnings: 'רווחים',
                notes: 'הערות',
                client_name: 'שם לקוח',
                client_check: 'אישור לקוח',
                view: 'צפייה',
                upload: 'העלאה',
                not_available: 'לא זמין',
                all_request: 'כל הסטטוסים',
                processing: 'בתהליך',
                request_accepted: 'בקשה התקבלה',
                request_denied: 'בקשה לא התקבלה',
                payment_process: 'הועבר לתשלום',
                paid: 'שולם',
                updateStatus: {
                    title: 'עדכון אישורים עבור',
                    approve: 'מאושר',
                    reject: 'סירוב',
                    none: 'טרם הוחלט',
                    submit: 'עדכן',
                    cancel: 'ביטול'
                },
                approveReject: {
                    title: 'שינוי סטטוס עבור בקשת תשלום',
                    approve: 'בקשה התקבלה',
                    reject: 'בקשה לא התקבלה',
                    process: 'בתהליך',
                    payment_process: 'הועבר לתשלום'
                }

            },
            dragAndDrop: {
              receipt: 'העלאת קובץ חשבונית / בקשה לתשלום / ניקוי מס במקור',
              invoice: 'העלאת קובץ חשבונית מס',
              submit: 'שלח בקשה',
              retry: 'נסה שנית'
            },
            status: {
                INACTIVE: 'לא פעיל',
                COMPANY_ACTIVE: 'ניהול שוטף חברה',
                PAYMENT_ISSUE: 'בעיה בתשלום',
                CUSTOMER_ACTIVE: 'ניהול שוטף',
                ON_PAUSE: 'בהקפאה',
                CANCELING: 'בתהליך ביטול',
                ALL: 'הכל',
                COMPANY_INACTIVE: 'חברה לא פעיל',
                BLACK_LIST: 'רשימה שחורה'
            }
        },
        transfersContent: {
            transfers: 'עסקאות',
            transactions: 'בהעברות',
            date_range: 'טווח תאריכים',
            start_date: 'בחר תאריך התחלה',
            end_date: 'בחר תאריך סיום',
            cancel: 'ביטול',
            load: 'טען מידע',
            added: 'נוסף בתאריך',
            count: 'כמות',
            domain: 'דומיין',
            actions: 'פעולות',
            date: 'תאריך תשלום',
            nisRate: 'שער דולר יציג',
            manage: 'אפשרויות',
            paid: 'שולם',
            type: 'מקור הכנסה',
            NIS: '₪ ש"ח',
            USD: '$ דולר',
            websiteID: 'מזהה אתר',
            customerID: 'מזהה לקוח',
            firstName: 'שם פרטי',
            lastName: 'שם משפחה',
            name: 'שם לקוח',
            rate: 'שער דולר יציג',
            filterRange: 'הכנסה לפי תארוך',
            invalidDate: 'תאריך לא חוקי',
            paid: 'הועבר לתשלום',
            not_paid: 'לא שולם',
            select_website: ': בחר אתר להעברה',
            view: 'צפה',
            show_stack: 'הראה העברות נבחרות',
            hide_stack: 'חזרה אחורה',
            buttons: {
                reset_filter: 'איפוס סינון',
                date_filter: 'סינון לפי תאריך',
                trasnfers_calculator: 'מחשבון העברות',
                reset: 'איפוס',
                mark_as_paid: 'סמן כשולם',
                mark_as_not_paid: 'סמן כלא שולם',
                filter: 'סינון',
                calculator: 'מחשבון',
                submit: 'שלח',
                cancel: 'ביטול'
            }

        },
        adminTable: {
            customer: 'לקוח',
            email: 'מייל',
            websites: 'אתרים',
            status: 'סטטוס',
            revenue: 'סך הכל הכנסות',
            withdrawn: 'שולם',
            last_transaction: 'העברה אחרונה',
            not_paid: 'סכום זמין למשיכה',
            added: 'נוסף',
            last_login: "כניסה אחרונה",
            add_new_user: 'הוסף לקוח חדש',
            title: 'לקוחות'
        },
        tableContent: {
            'SPONSORED_CONTENT': 'תוכן ממומן',
            'REGULAR': 'אתר',
            'PORTAL': 'פורטל',
            'BACKLINK': 'קישורים חיצוניים',
            'filter': 'תאריכון',
            'BUSY': 'עסוק',
            'CONTRACT_REQUIRED': "נדרש חוזה",
            'Not Paid': 'לא משולם',
            'Paid': 'הועבר לתשלום',
            'FREE': 'חופשי'
        },
        pages: {
            dashboard: 'דשבורד'
        },
        graphs: {
            incomeMonths: 'התפלגות הכנסה לפי חודשים',
            webSiteIncome: 'התפלגות הכנסה לפי אתר',
            dataType: 'נתונים: ',
            lastMonths: 'חודשים אחרונים',
            allTime: 'כל הזמן',
            analytics: ' כניסות ב30 ימים אחרונים'

        },
        sideBar: {
            newsletter: 'ניוזלטר',
            dashboard: 'דשבורד',
            websites: 'אתרים שלי',
            transactions: 'פעולות',
            profile: 'פרופיל',
            managing: 'מנהל',
            requests: 'בקשות תשלום'

        },
        sideBarAdmin: {
            newsletter: 'ניוזלטר',
            dashboard: 'דשבורד',
            websites: 'ניהול אתרים',
            transactions: 'פעולות',
            profile: 'פרופיל',
            managing: 'מנהל',
            messaging: 'מרכז הודעות',
            logs: 'לוג',
            onair: "תחילת ניהול שוטף",
            payments: 'תשלומים',
            employees: "עובדים",
            change_currency: 'שינוי שער מטבע'

        },
        navBar: {
            'Dashboard': 'דשבורד',
            'My Websites': 'ניהול אתרים',
            'Transactions': 'פעולות',
            'My Profile': 'פרופיל',
            'Management': 'מנהל',
            'Payments': 'תשלומים',
            'Newsletter': 'ניוזלטר',
            'On air': "תחילת ניהול שוטף",
            "Employees": "עובדים"
            
        },
        profile: {
            changeMail: 'שינוי כתובת אימייל',
            changePassword: 'שינוי סיסמת חשבון',
            updateBankAccount: 'עדכון פרטי תשלום',
            affiliate_since: 'הצטרף מאז',
        },
        changePassword: {
            title: 'שינוי סיסמא',
            current: 'סיסמא נוכחית',
            new: 'סיסמא חדשה',
            confirm: 'אישור סיסמא חדשה',
            update: 'עדכן סיסמא',
            cancel: 'ביטול'
        },
        changeEmail: {
            title: 'שינוי כתובת אימייל',
            current: 'כתובת אימייל נוכחית',
            new: 'כתובת אימייל חדשה',
            password: 'סיסמא',
            update: 'עדכון כתובת מייל',
            cancel: 'ביטול'
        },
        customersContent: {
            'Customers': 'לקוחות'
        },
        webSlider: {
            head: 'הגדרות אנאליטיקס',
            retainer: 'ריטיינר',
            noRetainer: 'אין ריטיינר',
            serviceStart: 'תאריך עליה לאוויר',
            buttons: {
                change_domain: 'שנויה תחום',
                edit: 'לערוך',
                change_vi: 'view ID שנויה',
                change_id: 'שנויה ID',
                change_ti: "Tracking ID שינוי",
                change_retainer: 'Retainer שינוי',
                change_time: 'שינוי תאריך',
                close: 'סגור'
            },
            domainDialog: {
                enter_new: 'Enter new'
            }
        },
        newCustomer: {
            addNew: 'הוסף לקוח',
            editUser: 'עריכת פרטי לקוח',
            firstNameENG: "שם פרטי אנגלית",
            companyNameENG: "שם החברה אנגלית",
            lastNameENG: "שם משפחה אנגלית",
            firstNameHEB: "שם פרטי עברית*",
            lastNameHEB: "שם משפחה עברית*",
            companyNameHEB: "שם החברה עברית",
            cityHEB: "עִיר עברית",
            streetHEB: "רחוב עברית",
            cityENG: "עיר אנגלית",
            streetENG: "רחוב אנגלית",
            phone: "מס' טלפון*",
            email: "אימייל*",
            password: "סיסמה*",
            repeat: "חזור על הסיסמה*",
            cancel: 'ביטול',
            submit: 'שלח'
        },
        newTransfer: {
            create: 'צור העברה חדשה',
            show_stack: 'הצג ערימה',
            hide_stack: 'חזרה אחורה',
            type: 'סוג',
            website: 'דומיין',
            count: 'כמות',
            amount_usd: 'סכום בדולרים',
            nis_rate: 'שער שקל-דולר',
            date: 'תאריך',
            cancel: 'ביטול',
            add: 'הוסף לערימה',
            submit: 'שלח'
        },
        Admin: {
            search: 'חיפוש לפי מייל',
            searchName: 'חיפוש לפי שם',
            byName: 'לפי שם',
            byEmail: 'לפי מייל',
            activeUsers: 'רק לקוחות פעילים',
            inactiveUsers: 'רק לקוחות לא פעילים',
            allUsers: 'כל הלקוחות',
            active: 'פעילים',
            inactive: 'לא פעילים',
            all: 'כל'
        },
        buttons: {
            submit: "שלח",
            cancel: "ביטול",
            active: "פעיל",
            inactive: "לא פעיל",
            edit: "שינוי",
            view: "להציג"
        },
        logs: {
            title: 'לוגים',
            impersonate: 'התחזות',
            customer_create: 'יצירת לקוח',
            website_active: 'שינוי ססטוס אתר לפעיל',
            website_others: 'שינוי סטטוס אתר (אחר)',
            on_air: 'יצירת קמפיינג',
            delete_on_air: 'מחיקת קמפיינג',
            transfer: 'יצירת העברות',
            delete_transfer: 'מחיקת העברות',
            move_transfer: 'העברת העברות',
            headers: {
                date: 'תאריך',
                type: 'סוג',
                responsible: 'אחראי',
                details: 'פרטים'
            },
            types: {
                website_status: 'סטטוס של אתר',
                transfer: 'המחאת כסף',
                transfer_del: "הסר את הטראנזקציה",
                new_customer: "לקואח חדש",
                onair: 'תחילת ניהול שוטף',
                delete_onair: 'הסר תחילת ניהול שוטף',
                transfer_move: 'העבר עסקה',
                impersonate: 'גישה לחשבון הלקוח'
            },
            yesterday: 'אתמול',
            today: 'היום'
        },
        campaigns: {
            new: 'צור חדש',
            wait: 'רק רגע',
            done: 'עשוי',
            go: 'להמשיך',
            cancel: 'ביטול',
            startDate: 'תאריך התחלה',
            error: 'משהו השתבש',
            history: 'היסטוריה',
            create_new: 'צור קמפיינג חדש',
            on_air: 'חזרה אחורה',
            title: 'קמפיינים',
            table: {
                confirmations: 'סטטוס אישור',
                added: 'נוסף',
                customer: 'לקוח',
                website: 'אתר',
                status: 'סטטוס פריט',
                start: 'תאריך התחלה',
                empty: "כרגע אין קמפיינים",
                default: 'רגיל',
                retainer: 'ריטיינר עתידי',
                additionalNote: "פתק",
                delete: "מחיקה",
                pending: {
                    wait: 'מחכה לאישור',
                    ready: 'מוכן לרוץ בזמן'
                },
                confirmation: {
                    approve: 'אשר והמשיך',
                    decline: 'לחסום',
                    reason: 'סיבה',
                    h1: 'אנא אשר או דחה את הפריט הבא',
                    summary: 'סיכום קצר',
                    details: {
                        firstName: 'שם פרטי',
                        lastName: 'שם משפחה',
                        email: 'אימייל',
                        website: 'אתר'
                    }
                }
            }
        }

    }
}

export default NAMES;