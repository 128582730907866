import classes from './LoadingTable.module.scss'

const LoadingTable = () => {
    return (
        <div className={classes.container}>
            <div className={classes.spinner}><div></div><div></div><div></div></div>
        </div>
    )
}

export default LoadingTable