import classes from './ChangeEmail.module.scss';

import email_icon from '../../assets/icons/email.svg'
import email_green from '../../assets/icons/email_green.svg'
import email_blue from '../../assets/icons/email_blue.svg'
import email_red from '../../assets/icons/email_red.svg'
import lock from '../../assets/icons/lock.svg'
import lock_green from '../../assets/icons/lock_green.svg'
import lock_blue from '../../assets/icons/lock_blue.svg'
import lock_red from '../../assets/icons/lock_red.svg'

import PopUpCard from '../Cards/PopUpCard/PopUpCard';
import CustomInput from '../Customs/CustomInput/CustomInput';
import CustomButton from '../Customs/CustomButton';
import CheckAnimation from '../CheckAnimation/CheckAnimation';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../store/user-slice';

const ChangeEmail = (props) => {

    const dispatch = useDispatch()
    const names = useSelector(state => state.ui.language.values.changeEmail)
    const email = useSelector(state => state.user.email)
    const api_token = useSelector(state => state.user.token)

    const [currentEmail, setCurrentEmail] = useState(email)
    const [newEmail, setNewEmail] = useState('')
    const [password, setPassword] = useState('')

    const [currentEmailStatus, setCurrentEmailStatus] = useState('success')
    const [newEmailStatus, setNewEmailStatus] = useState('pending')
    const [passwordStatus, setPasswordStatus] = useState('pending')

    const [currentEmailIcon, setCurrentEmailIcon] = useState(email_green)
    const [newEmailIcon, setNewEmailIcon] = useState(email_icon)
    const [passwordIcon, setPasswordIcon] = useState(lock)

    const [showCheckAnimation ,setShowCheckAnimation] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    const currentEmailHandler = (e) => {
        let value = e.target.value
        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

        if(value.length === 0) {
            setCurrentEmailIcon(email_icon)
        }
        else if(EMAIL_REGEX.test(value)) { 
            setCurrentEmailStatus('success')
            setCurrentEmailIcon(email_green)
        }
        else { 
            setCurrentEmailStatus('pending')
            setCurrentEmailIcon(email_blue)
        } 
        setCurrentEmail(e.target.value)
    }

    const newEmailHandler = (e) => {
        let value = e.target.value
        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

        if(value.length === 0) {
            setNewEmailIcon(email_icon)
        }
        else if(EMAIL_REGEX.test(value)) { 
            setNewEmailStatus('success')
            setNewEmailIcon(email_green)
        }
        else { 
            setNewEmailStatus('pending')
            setNewEmailIcon(email_blue)
        } 
        setNewEmail(e.target.value)
    }

    const passwordHandler = (e) => {
        let value = e.target.value

        if(value.length === 0) {
            setPasswordIcon(lock)
        }
        else if(value.length >= 4) { 
            setPasswordStatus('success')
            setPasswordIcon(lock_green)
        }
        else { 
            setPasswordStatus('pending') 
            setPasswordIcon(lock_blue)
        }

        setPassword(value)
    }

    const submitHandler = async (e) => {
        e.preventDefault()

        if(currentEmailStatus === 'success' && newEmailStatus === 'success' && passwordStatus === 'success') {

            const request_options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${api_token}`},
                body: JSON.stringify({
                    email: currentEmail,
                    newEmail: newEmail,
                    password: password
                })
            }

            let response = await fetch(`${API_URL}/api/v1/users/settings`,request_options)
            const res_status = response.status
            response = await response.json()

            if(res_status === 200) {
                setShowCheckAnimation(true)
                dispatch(userActions.setEmail(newEmail))

                setTimeout(() => {
                    props.toggle()
                },1500)
            }
            if(res_status !== 200) {
                if(response.error === 'email already in the system') {
                    setNewEmailStatus('error')
                    setNewEmailIcon(email_red)
                }
                if(response.error === 'incorrect password') {
                    setPasswordStatus('error')
                    setPasswordIcon(lock_red)
                }
            }
        }

        else {
            if(!(currentEmailStatus === 'success')) {
                setCurrentEmailStatus('error')
                setCurrentEmailIcon(email_red)
            }
            if(!(newEmailStatus === 'success')) {
                setNewEmailStatus('error')
                setNewEmailIcon(email_red)
            }
            if(!(passwordStatus === 'success')) {
                setPasswordStatus('error')
                setPasswordIcon(lock_red)
            }
        }
    }

    return (
        <PopUpCard toggle={props.toggle} header={names.title}>
            <form className={classes.main_middle} onSubmit={submitHandler}>
                {showCheckAnimation && <CheckAnimation />}
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.current}</label>
                    <CustomInput image_src={currentEmailIcon} placeholder='email@email.com' name='email' type='email' onChange={currentEmailHandler} value={currentEmail} required status={currentEmailStatus}/>
                </div>
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.new}</label>
                    <CustomInput image_src={newEmailIcon} placeholder='email@email.com' name='newemail' type='email' onChange={newEmailHandler} value={newEmail} required status={newEmailStatus}/>
                </div>
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.password}</label>
                    <CustomInput image_src={passwordIcon} placeholder='************' name='password' type='password' onChange={passwordHandler} value={password} required status={passwordStatus}/>
                </div>

                <div className={classes.buttons}>
                        <div className={classes.cancel} onClick={props.toggle}>{names.cancel}</div>
                        <div className={classes.customButton}><CustomButton reverse color='red' name={names.update} type='submit'/></div>
                </div>
            </form>
        </PopUpCard>
    )
}

export default ChangeEmail;