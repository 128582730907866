import classes from './TopBar.module.scss'

import names from '../../utils/names_langs'
import CustomSelect from '../Customs/CustomSelect/CustomSelect';
import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium';

import translate from '../../assets/icons/translate.svg'
import logout from '../../assets/icons/logout.svg'

import { useSelector, useDispatch } from 'react-redux';
import {uiActions} from '../../store/ui-slice'
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';

import { booleanParser } from '../../utils/helper_functions';

const TopBar = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()

    const headersAdmin = useSelector(state => state.ui.language.values.sideBarAdmin)
    const headersUser = useSelector(state => state.ui.language.values.sideBar)
    const language_code = useSelector(state => state.ui.language.code)

    const user = useSelector(state => state.user)
    const isAdmin = user.isAdmin
    const showAdminInterface = useSelector(state => state.ui.showAdminInterface)
    const currentRate = user.currentRate

    const toggleLanguageHandler = () => {
        if(language_code === 'ENG') {
            dispatch(uiActions.toggleLanguage({
                values: names.HEB,
                name: 'hebrew',
                code: 'HEB'
            }))
        }
        if(language_code === 'HEB') {
            dispatch(uiActions.toggleLanguage({
                values: names.ENG,
                name: 'english',
                code: 'ENG'
            }))
        }
    }

    const toggleAdminHandler = (e) => {
        dispatch(uiActions.setShowAdminInterface(booleanParser(e.target.value)))
    }

    const logoutHandler = () => {

        if('$crisp' in window){
            window.$crisp.push(["do", "session:reset"]);
            window.$crisp = []
        }

        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        dispatch(uiActions.setIsLoggedIn(false))

        if(isAdmin) navigate('/login/admin',{ replace: true })
        else navigate('/login',{ replace: true })
    }

    const titleChangeHandler = () => {
        let url_page = params.page
        if(url_page === 'dashboard') return headersAdmin.dashboard
        if(url_page === 'websites') return showAdminInterface ? headersAdmin.websites : headersUser.websites
        if(url_page === 'onair') return headersAdmin.onair
        if(url_page === 'transactions') return headersAdmin.transactions
        if(url_page === 'customers') return headersAdmin.managing
        if(url_page === 'logs') return headersAdmin.logs
        if(url_page === 'profile') return headersUser.profile
        if(url_page === 'payments') return headersAdmin.payments

        return 'go to top bar and implement'
    }

    return (
        <div className={classes.container}>

            <div className={classes.left_side}>
                <div className={classes.title}>{titleChangeHandler()}</div>
            </div>

            <div className={classes.right_side}>

                <div className={classes.convertion_container}>
                    <div className={classes.convertion}>{`1$ = ${currentRate.rate} NIS`}</div>
                    <div className={classes.convertion_date}>{'שער דולר ממוצע'}</div>
                </div>

                {isAdmin && 
                <CustomSelect onChange={toggleAdminHandler} topbar>
                    <option value={true} defaultValue={showAdminInterface}>Admin</option>
                    <option value={false} defaultValue={!showAdminInterface}>User</option>
                </CustomSelect>
                }

                <div className={classes.language_container} onClick={toggleLanguageHandler}>
                    <IconContainerMedium color='red' image_src={translate} alt='translate'/>
                </div>
           
                <div className={classes.logout} onClick={logoutHandler}>
                    <IconContainerMedium color='black' image_src={logout} alt='logout'/>
                </div>
                    
            </div>
        </div>
    )
}

export default TopBar;