
const HeadersOnlyPortal = (props) => {

    let names = props.names

    return (
        <tr>
            <th>{names.first_name}</th>
            <th>{names.last_name}</th>
            <th>{names.email}</th>
            <th>{names.status}</th>
            <th style={{textAlign: 'center', paddingRight: '2rem'}}>{names.units}</th>
            <th>{names.portal}</th>
    </tr>
    )

}

export default HeadersOnlyPortal