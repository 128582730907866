import classes from './PaymentAdmin.module.scss';

import excel_icon from '../../assets/icons/excel.svg'

import CustomSelect from '../Customs/CustomSelect/CustomSelect'
import SearchBar from '../SearchBar/SearchBar';
import AmountSelect from '../AmountSelect/AmountSelect';
import Pagination from '../Pagination/Pagination';
import CustomButton from '../Customs/CustomButton';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';
import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium';

import PaymentAdminHeaders from './PaymentAdminHeaders';
import PaymentAdminTableItem from './PaymentAdminTableItem';
import PaymentInvoiceContainer from '../PaymentInvoiceContainer/PaymentInvoiceContainer'
import UpdateStatus from './UpdateStatus/UpdateStatus';
import RejectApprovePayment from './RejectApprovePayment/RejectApprovePayment';
import FinalApprovment from './FinalApprovment/FinalApprovment'
import ChargeRefundUpdate from './ChargeRefundUpdate/ChargeRefundUpdate';
import DownloadDocuments from './DownloadDocuments/DownloadDocuments';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CSVLink } from "react-csv";

import { clearAllTimeouts, fullYearParser, hoursMinutesParser, paymentAdminCSVHandler, TimeOutHandler} from '../../utils/helper_functions';


const PaymentAdmin = () => {

    const names = useSelector(state => state.ui.language.values.websitesContent.payments)
    const isAdmin = useSelector(state => state.user.isAdmin)
    const current_language = useSelector(state => state.ui.language.code)
    const api_token = useSelector(state => state.user.token)

    const [loadingResults, setLoadingResults] = useState(false)
    const [forceUpdate, setForceUpdate] = useState(true)

    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(20)
    const [numberOfPages, setNumberOfPages] = useState(1)

    const [searchQuery, setSearchQuery] = useState('')
    const [requestStatus, setRequestStatus] = useState('all')
    const [clientCheck, setClientCheck] = useState(false)
    const [taxInvoice, setTaxInvoice] = useState(false)
    const [receipt, setReceipt] = useState(false)
    const [chargeRefund, setChargeRefund] = useState(false)

    const [Data, setData] = useState([])

    const [showPageInvoiceContainer, setShowPageInvoiceContainer] = useState(false)
    const [showUpdateStatus, setShowUpdateStatus] = useState(false)
    const [showRejectApprovePayment, setShowRejectApprovePayment] = useState(false)
    const [showChargeRefundUpdate, setShowChargeRefundUpdate] = useState(false)
    const [showDownloadDocuments, setShowDownloadDocuments] = useState(false)
    const [showCOOApprove, setShowCOOApprove] = useState(false)
    const [showCFOApprove, setShowCFOApprove] = useState(false)

    const [InvoiceSrc, setInvoiceSrc] = useState('')
    const [paymentData, setPaymentData] = useState({})
    const [downloadDocuments,setDownloadDocuments] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    let csv_name = new Date()
    csv_name = `${fullYearParser(csv_name)}-${hoursMinutesParser(csv_name)}`

    useEffect(async () => {

        if(searchQuery.length === 0) {
            setLoadingResults(true)
            await getPayments()
            setLoadingResults(false)
        }
        else {

            clearAllTimeouts()

            TimeOutHandler(async() => {
    
                setLoadingResults(true)
                await getPayments()
                setLoadingResults(false)
    
            },600)
        }

    },[pageNumber,numberOfResults,requestStatus,clientCheck,taxInvoice,receipt,chargeRefund,forceUpdate,searchQuery])

    const forceUpdateHandler = () => {
        // The Value is meaningless
        setForceUpdate(val => !val)
    }

    const getPayments = async () => {

 
        const response = await fetch(`${API_URL}/api/v2/getpaymentrequests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                page: pageNumber,
                items: numberOfResults,
                searchName: searchQuery.length > 0 ? searchQuery : null,
                client_check: clientCheck ? clientCheck : null,
                receipt_tax_invoice: taxInvoice ? taxInvoice : null,
                receipt_status: receipt ? receipt : null,
                charge_refund: chargeRefund ? chargeRefund : null,
                request_status: requestStatus !== 'all' ? requestStatus : undefined
            })
        })
        
        const res_status = response.status
        const data = await response.json()


        if(res_status === 200) {
            setNumberOfPages(Math.ceil(data.entries/numberOfResults))
            setData(data.result)
        }
    }

    const getAllDocumentsPDF = async (receipt,invoice) => {

        setShowDownloadDocuments(true)

        const paymentRequestIDs = Data.map((payment) => payment.paymentRequestID)

        let response = await fetch(`${API_URL}/api/v2/getpaymentrequestdocument`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestIDs: paymentRequestIDs,
                receipt_img: receipt ? receipt : false,
                tax_invoice_img: invoice ? invoice : false
            })
        })

        response = await response.json()
        setDownloadDocuments(response.document)
        window.open(response.document, '_blank')
        setShowDownloadDocuments(false)
    }


    const showInvoiceHandler = (image_src,data) => {
        setPaymentData(data)
        setInvoiceSrc(image_src)
        setShowPageInvoiceContainer(true)
    }

    const showUpdateStatusHandler = (data) => {
        setPaymentData(data)
        setShowUpdateStatus(true)
    }

    const showRejectApprovePaymentHandler = (data) => {
            setPaymentData(data)
            setShowRejectApprovePayment(true)
        
    }

    const showChargeRefundUpdateHandler = (data) => {
        setPaymentData(data)
        setShowChargeRefundUpdate(true)
    }

    const toggleShowCFOApprove = () => {setShowCFOApprove(val => !val)}

    const toggleShowCOOApprove = () => {setShowCOOApprove(val => !val)}


    return (
        <>
        {showPageInvoiceContainer && <PaymentInvoiceContainer data={paymentData} image_src={InvoiceSrc} toggle={() => setShowPageInvoiceContainer(val => !val)}/>}
        {showUpdateStatus && <UpdateStatus names={names} data={paymentData} toggle={() => setShowUpdateStatus(val => !val)} forceUpdate={forceUpdateHandler}/>}
        {showRejectApprovePayment && <RejectApprovePayment names={names} data={paymentData} toggle={() => setShowRejectApprovePayment(val => !val)} forceUpdate={forceUpdateHandler}/>}
        {showCOOApprove && <FinalApprovment names={names} role='COO' toggle={toggleShowCOOApprove} forceUpdate={forceUpdateHandler}/>}
        {showCFOApprove && <FinalApprovment names={names} role='CFO' toggle={toggleShowCFOApprove} forceUpdate={forceUpdateHandler}/>}
        {showChargeRefundUpdate && <ChargeRefundUpdate data={paymentData} toggle={() => setShowChargeRefundUpdate(false)} forceUpdate={forceUpdateHandler}/>}
        {showDownloadDocuments && <DownloadDocuments toggle={() => setShowDownloadDocuments(false)} data={downloadDocuments}/>}
        <div className={classes.container}>
            <div className={classes.main}>

                <div className={classes.main_top}>
                    <div className={classes.main_title}>{names.payments_requests}</div>
                    <div className={classes.main_buttons}>
                        {isAdmin && <CustomButton name='CFO' color='blue' reverse onClick={toggleShowCFOApprove}/>}
                        {isAdmin && <CustomButton name='COO' color='blue' reverse onClick={toggleShowCOOApprove}/>}
                        <CustomButton name='הדפס מסמכים' color='red' reverse onClick={() => getAllDocumentsPDF(true,false)}/>
                        <CustomButton name='הדפס חשבוניות' color='red' reverse onClick={() => getAllDocumentsPDF(false,true)}/>
                        <CSVLink data={paymentAdminCSVHandler(Data,current_language)} filename={`${csv_name}.csv`}>
                            <IconContainerMedium image_src={excel_icon} image_alt='excel' color='green'/>
                        </CSVLink>
                        <CustomSelect value={requestStatus} onChange={(e) => setRequestStatus(e.target.value)}>
                            <option value='all'>{names.all_request}</option>
                            <option value='processing'>{names.processing}</option>
                            <option value='request accepted'>{names.request_accepted}</option>
                            <option value='request declined'>{names.request_denied}</option>
                            <option value='payment process'>{names.payment_process}</option>
                            <option value='paid'>{names.paid}</option>
                        </CustomSelect>
                    </div>
                </div>

                <div className={classes.main_middle}>
                    <div className={classes.filter}>
                        <SearchBar placeholder='Search by Name' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                        <div className={classes.filter_checkboxes}>
                            <div className={classes.filter_checkboxes_item}>
                                <input type='checkbox' value={receipt} onChange={(e) => setReceipt(e.target.checked)}/>
                                <label>סירוב מסמך</label>
                            </div>
                            <div className={classes.filter_checkboxes_item}>
                                <input type='checkbox' value={clientCheck} onChange={(e) => setClientCheck(e.target.checked)}/>
                                <label>סירוב לקוח</label>
                            </div>
                            <div className={classes.filter_checkboxes_item}>
                                <input type='checkbox' value={taxInvoice} onChange={(e) => setTaxInvoice(e.target.checked)}/>
                                <label>סירוב קבלה/חשבונית</label>
                            </div>
                        </div>
                        <div className={classes.filter_checkboxes}>
                            <div className={classes.filter_checkboxes_item}>
                                <input type='checkbox' onClick={(e) => setChargeRefund(e.target.checked)}/>
                                <label>{names.charge_refund}</label>
                            </div>
                        </div>
                    </div>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={(e) => setNumberOfResults(e.target.value)}/>
                </div>

                <ResponsiveTable loadingResults={loadingResults}>
                        <thead>
                            <PaymentAdminHeaders names={names}/>
                        </thead>
                        <tbody className={classes.table_data}>
                            {Data.map((item,index) => <PaymentAdminTableItem
                                                            key={index}
                                                            data={item}
                                                            names={names}
                                                            onShowInvoice={showInvoiceHandler}
                                                            onChangeStatus={() => showUpdateStatusHandler(item)}
                                                            onRejectApprove={() => showRejectApprovePaymentHandler(item)}
                                                            onChargeRefund={() => showChargeRefundUpdateHandler(item)}/>)}
                        </tbody>
                </ResponsiveTable>
                  
                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={(e) => setNumberOfResults(e.target.value)}/>
                    {numberOfPages > 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>
            </div>
        </div>
        </>
    )
}

export default PaymentAdmin;