import classes from './ChargeRefundUpdate.module.scss';

import PopUpCard from "../../Cards/PopUpCard/PopUpCard";
import CustomButton from '../../Customs/CustomButton';
import CheckAnimation from '../../CheckAnimation/CheckAnimation';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TimeOutHandler } from '../../../utils/helper_functions';

const ChargeRefundUpdate = (props) => {

    const data = props.data
    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL

    const [invoiceId, setInvoiceId] = useState(data.charge_refund ? data.charge_refund_details.invoice_id : '')
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)

    console.log(data)

    const submitHandler = async () => {

        let response = await fetch(`${API_URL}/api/v2/paymentrequestcontroller`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestID: data.paymentRequestID,
                charge_refund: true,
                amount: props.data.amount.NIS,
                invoice_id: invoiceId
            })
        })

        const res_status = response.status
        response = response.json()

        if(res_status === 200) {
            setShowCheckAnimation(true)
            TimeOutHandler(() => {
                props.toggle()
                props.forceUpdate()
            })
        }

    }

    return (
        <PopUpCard header='עדכון חיוב זיכוי' toggle={props.toggle}>
            <div className={classes.main_middle}>
                {showCheckAnimation && <CheckAnimation />}
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>:סכום</label>
                    <div className={classes.middle_item_input}>
                        <input style={{cursor: 'not-allowed'}} disabled value={`₪ ${props.data.amount.NIS}`}/>
                    </div>
                </div>
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>:מספר חשבונית</label>
                    <div className={classes.middle_item_input}>
                        <input placeholder='123456789' value={invoiceId} onChange={(e) => setInvoiceId(e.target.value)}/>
                    </div>
                </div>

                <div className={classes.buttons}>
                    <div className={classes.cancel} onClick={props.toggle}>חזרה אחורה</div>
                    <div className={classes.customButton} onClick={submitHandler}><CustomButton reverse color='red' name='עדכן'/></div>
                </div>
            </div>
        </PopUpCard>
    )

}

export default ChargeRefundUpdate;