import PopUpCard from "../../Cards/PopUpCard/PopUpCard"
import LoadingTable from "../../LoadingTable/LoadingTable"

import { useState, useEffect } from "react"

const DownloadDocuments = (props) => {

    const [isLoading, setIsLoading] = useState(true)

    console.log(props.data)

    return (
        <PopUpCard header='הורדת מסמכים' toggle={props.toggle}>
            <div>
                <div style={{display: 'flex', flexDirection: 'column', fontSize: '1.6rem', textAlign: 'center'}}>
                    <span>עובדים יקרים, נא לוודא שהדפדפן לא חוסם פופ-אפ מהאתר</span>
                    <span>לשם הצלחת ההורדה</span>
                </div>
                {isLoading && <LoadingTable />}
            </div>
        </PopUpCard>
    )

}

export default DownloadDocuments