import classes from './RejectApprovePayment.module.scss'

import PopUpCard from "../../Cards/PopUpCard/PopUpCard";
import CustomButton from "../../Customs/CustomButton";

import { useSelector } from 'react-redux';

import { fullYearParser, paymentsStatusesHandler } from '../../../utils/helper_functions';

const RejectApprovePayment = (props) => {

    const current_language = useSelector(state => state.ui.language.code)
    const isAdmin = useSelector(state => state.ui.language.code)
    const data = props.data
    const names = props.names
    const added = fullYearParser(data.created)

    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL

    const ApproveHandler = async () => {


        if(!data.receipt_status || !data.client_check) {
            alert('לא ניתן לאשר בקשה ללא האישורים המתאימים')
            return
        }

        if(data.request_status === 'paid') {
            const res = await cancelResetActionHandler()
            if(res !== 200) {
                alert('משהו השתבש')
                return;
            }
        }

        const date = new Date().toISOString()
        
        let response = await fetch(`${API_URL}/api/v2/paymentrequestcontroller`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestID: data.paymentRequestID,
                request_status: 'request accepted',
                approved_date: date,
                receipt_status_reason: '',
                client_check_reason: '',
                receipt_tax_invoice_reason: ''
            })
        })

        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            props.forceUpdate()
            props.toggle()
        }
    }

    const rejectHandler = async () => {

        if(data.request_status === 'paid') {
            const res = await cancelResetActionHandler()
            if(res !== 200) {
                alert('משהו השתבש')
                return;
            }
        }

        let response = await fetch(`${API_URL}/api/v2/paymentrequestcontroller`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestID: data.paymentRequestID,
                request_status: 'request declined',
                receipt_status_reason: data.refuse_reason.receipt_status_reason,
                client_check_reason: data.refuse_reason.client_check_reason,
                receipt_tax_invoice_reason: data.refuse_reason.receipt_tax_invoice_reason,
                receipt_status: data.receipt_status,
                client_check: data.client_check,
                receipt_tax_invoice: data.receipt_tax_invoice
            })
        })

        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            props.forceUpdate()
            props.toggle()
        }
    }

    const processingHandler = async () => {

        if(data.request_status === 'paid') {
            const res = await cancelResetActionHandler()
            if(res !== 200) {
                alert('משהו השתבש')
                return;
            }
        }

        let response = await fetch(`${API_URL}/api/v2/paymentrequestcontroller`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestID: data.paymentRequestID,
                request_status: 'processing',
            })
        })

        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            props.forceUpdate()
            props.toggle()
        }
    }

    const paymentProcessHandler = async() => {

        if(data.request_status === 'paid') {
            const res = await cancelResetActionHandler()
            if(res !== 200) {
                alert('משהו השתבש')
                return;
            }
        }

        let response = await fetch(`${API_URL}/api/v2/paymentrequestcontroller`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestID: data.paymentRequestID,
                request_status: 'payment process',
                approved_date: null,
                receipt_status_reason: '',
                client_check_reason: '',
                receipt_tax_invoice_reason: '',
                receipt_status: null,
                client_check: null,
                receipt_tax_invoice: null,
            })
        })

        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            props.forceUpdate()
            props.toggle()
        }
    }

    const cancelResetActionHandler = async () => {
        
        let response = await fetch(`${API_URL}/api/v2/employees/transfersresetcancel`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestID: data.paymentRequestID
            })
        })

        const res_status = response.status
        response = await response.json()

        return res_status

    }

    return (
        <PopUpCard header={names.approveReject.title} toggle={props.toggle}>
            <div className={classes.main}>
            <div className={classes.data}>
                <div className={classes.data_item}>
                    <span>
                        {`${names.status}: `}
                        <span style={{textTransform: 'capitalize', fontWeight: 'bolder'}}>{`${paymentsStatusesHandler(data.request_status,current_language)}`}</span>
                    </span>
                   
                </div>
                <div className={classes.data_item}>
                    <span>{`${names.receipt_status}`}</span>
                    <span className={data.receipt_status ? classes.approved : classes.denied}>
                        {data.receipt_status === true ? 'Approved' : data.receipt_status === false ? 'Denied' : 'None'}
                    </span>
                </div>
                <div className={classes.data_item}>
                    <span>{`${names.date}: `+`${added}`}</span>
                </div>
                <div className={classes.data_item}>
                    <span>{`${names.receipt_tax}`}</span>
                    <span className={data.receipt_tax_invoice ? classes.approved : classes.denied}>
                        {data.receipt_tax_invoice === true ? 'Approved' : data.receipt_tax_invoice === false ? 'Denied' : 'None'}
                    </span>
                </div>
                <div className={classes.data_item}>
                    <span>{`${names.amount}: `+`₪ ${data.amount.NIS}`}</span>
                </div>
                <div className={classes.data_item}>
                    <span>{`${names.client_check}`}</span>
                    <span className={data.client_check ? classes.approved : classes.denied}>
                        {data.client_check === true ? 'Approved' : data.client_check === false ? 'Denied' : 'None'}
                    </span>
                </div>
                
            </div>
            <div>
                <div className={classes.buttons}>
                    <CustomButton name={names.approveReject.reject} color='red' reverse onClick={rejectHandler}/>
                    <CustomButton name={names.approveReject.approve} color='green' reverse onClick={ApproveHandler}/>
                    
                </div>
                {(isAdmin && data.request_status === 'paid') &&
                <div className={classes.buttons}>
                    <CustomButton name={names.approveReject.payment_process} color='blue' reverse onClick={paymentProcessHandler}/>
                    <CustomButton name={names.approveReject.process} color='orange' reverse onClick={processingHandler}/>
                </div>           
                }
                </div>
            </div>
        </PopUpCard>
    )

}

export default RejectApprovePayment;