import classes from './DashboardCard.module.scss'

import IconContainerLarge from '../Customs/IconContainerLarge/IconContainerLarge';
const DashboardCard = (props) => {
    return (
    <div className={classes.container}>
        <IconContainerLarge image_src={props.image} color='red'/>
        <div className={classes.details_container}>
            <div className={classes.title}>{props.title || 'Add Title'}</div>
            <div className={classes.value}>{`₪ ${props.value || 0}`}</div>
        </div>
    </div>)
}

export default DashboardCard;