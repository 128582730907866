import classes from './ChangePassword.module.scss';

import lock from '../../assets/icons/lock.svg'
import lock_green from '../../assets/icons/lock_green.svg'
import lock_blue from '../../assets/icons/lock_blue.svg'
import lock_red from '../../assets/icons/lock_red.svg'

import PopUpCard from '../Cards/PopUpCard/PopUpCard';
import CustomInput from '../Customs/CustomInput/CustomInput';
import CustomButton from '../Customs/CustomButton';
import CheckAnimation from '../CheckAnimation/CheckAnimation';

import { useState } from 'react';
import { useSelector } from 'react-redux';

const ChangePassword = (props) => {

    const names = useSelector(state => state.ui.language.values.changePassword)
    const api_token = useSelector(state => state.user.token)

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword , setConfirmPassword] = useState('')

    const [currentPasswordStatus, setCurrentPasswordStatus] = useState('pending')
    const [newPasswordStatus, setNewPasswordStatus] = useState('pending')
    const [confirmPasswordStatus , setConfrimPasswordStatus] = useState('pending')

    const [currentPasswordIcon, setCurrentPasswordIcon] = useState(lock)
    const [newPasswordIcon, setNewPasswordIcon] = useState(lock)
    const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(lock)

    const [showCheckAnimation ,setShowCheckAnimation] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    const currentPasswordHandler = (e) => {
        let value = e.target.value

        if(value.length === 0) {
            setCurrentPasswordIcon(lock)
        }
        else if(value.length >= 4) { 
            setCurrentPasswordStatus('success')
            setCurrentPasswordIcon(lock_green)
        }
        else { 
            setCurrentPasswordStatus('pending') 
            setCurrentPasswordIcon(lock_blue)
        }
        setCurrentPassword(value)
    }

    const newPasswordHandler = (e) => {
        let value = e.target.value

        if(value.length === 0) {
            setNewPasswordIcon(lock)
        }
        else if(value.length >= 4) { 
            setNewPasswordStatus('success')
            setNewPasswordIcon(lock_green)
        }
        else { 
            setNewPasswordStatus('pending') 
            setNewPasswordIcon(lock_blue)
        }
        setNewPassword(value)
    }

    const confirmPasswordHandler = (e) => {
        let value = e.target.value

        if(value.length === 0) {
            setConfirmPasswordIcon(lock)
        }
        else if(value.length >= 4 && (value.localeCompare(newPassword) === 0)) {
            setConfrimPasswordStatus('success')
            setConfirmPasswordIcon(lock_green)
        }
        else if((value.length >= newPassword.length && (value.localeCompare(newPassword) !== 0)) || !newPassword.startsWith(value)) {
            setConfrimPasswordStatus('error')
            setConfirmPasswordIcon(lock_red)
        }
        else { 
            setConfrimPasswordStatus('pending') 
            setConfirmPasswordIcon(lock_blue)
        }
        setConfirmPassword(value)
    }

    const submitHandler = async (e) => {
        e.preventDefault()

        if(currentPasswordStatus === 'success' && newPasswordStatus === 'success' && confirmPasswordStatus === 'success') {
            const request_options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${api_token}`},
                body: JSON.stringify({
                    password: currentPassword,
                    newPassword: newPassword
                })
            }

            let response = await fetch(`${API_URL}/api/v1/users/settings`,request_options)
            const res_status = response.status
            response = await response.json()

            if(res_status === 200) {
                setShowCheckAnimation(true)

                setTimeout(() => {
                    props.toggle()
                },1500)
            }
            else {
                setCurrentPasswordStatus('error')
                setCurrentPasswordIcon(lock_red)
            }
        }
    }

    return (
        <PopUpCard toggle={props.toggle} header={names.title}>
            <form className={classes.main_middle} onSubmit={submitHandler}>
                {showCheckAnimation && <CheckAnimation />}
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.current}</label>
                    <CustomInput image_src={currentPasswordIcon} placeholder='************' name='password' type='password' onChange={currentPasswordHandler} value={currentPassword} required status={currentPasswordStatus}/>
                </div>
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.new}</label>
                    <CustomInput image_src={newPasswordIcon} placeholder='************' name='newpassword' type='password' onChange={newPasswordHandler} value={newPassword} required status={newPasswordStatus}/>
                </div>
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.confirm}</label>
                    <CustomInput image_src={confirmPasswordIcon} placeholder='************' name='confrim' type='password' onChange={confirmPasswordHandler} value={confirmPassword} required status={confirmPasswordStatus}/>
                </div>

                <div className={classes.buttons}>
                        <div className={classes.cancel} onClick={props.toggle}>{names.cancel}</div>
                        <div className={classes.customButton}><CustomButton reverse color='red' name={names.update} type='submit'/></div>
                </div>
            </form>

        </PopUpCard>
    )

}

export default ChangePassword;