/**
 * Copied from https://www.sitepoint.com/react-toggle-switch-reusable-component/
 */

import './CustomToggle.scss'

const CustomToggle = (props) => {
    return (
        <div className="toggle-switch" onClick={props.onClick}>
            <input
            type="checkbox"
            className="toggle-switch-checkbox"
            name={props.Name}
            id={props.Name}
            checked={props.checked}
            onChange={() => {}}
            />
            <label className="toggle-switch-label" htmlFor={props.Name}>
            <span className="toggle-switch-inner" />
            <span className="toggle-switch-switch" />
            </label>
      </div>
    )
}

export default CustomToggle