import classes from './EditUserWebsites.module.scss'

import exit from '../../assets/icons/exit.svg'
import delete_icon from '../../assets/icons/delete.svg'
import transfere from '../../assets/icons/transfere.svg'

import GrayWindow from '../Cards/GrayWindow/GrayWindow'
import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium'
import CustomButton from '../Customs/CustomButton'
import DeleteConfirmation from '../Cards/DeleteConfirmation/DeleteConfirmation'

import AddNewWebsite from './AddNewWebsite/AddNewWebsite'
import TransferWebsite from './TransferWebsite/TransferWebsite'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { statusParser } from '../../utils/helper_functions'

const EditUserWebsites = (props) => {

    const [showAddNewWebsite, setShowAddNewWebsite] = useState(false)
    const [showTransfereWebsite, setShowTransfereWebsite] = useState(false)
    const [websiteToTransfer, setWebsiteToTransfer] = useState('')

    const [showDeleteConfimration, setShowDeleteConfirmation] = useState(false)
    const [websiteToDelete, setWebsiteToDelete] = useState('')

    const api_token = useSelector(state => state.user.token)
    const data = props.data
    const API_URL = process.env.REACT_APP_API_URL


    const navigate = useNavigate()

    const deleteConfirmationHandler = (websiteid) => {
        setWebsiteToDelete(websiteid)
        setShowDeleteConfirmation(true)
    }
    
    const deleteHandler = async () => {
        let filtered_websites = data.websites.filter((website) => website.websiteID !== websiteToDelete)

        let response = await fetch(`${API_URL}/api/v2/employees/websites/status`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json','authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                websiteID: websiteToDelete,
                status: 'CUSTOMER_LEFT',
                customerID: data.customerID

            })
        })
        let response_status = response.status
        response = await response.json()

        if(response_status === 200) {
            props.updateData((item) => { return {...item, websites: filtered_websites}})
            setShowDeleteConfirmation(false)
        }
        else {
            alert('something went wrong, try again')
        }
        
    }

    const navigateToWebsiteHandler = (domain) => {
        navigate(`/user/websites?url=${domain}`, {replace: true})
    }

    return (
        <>
        <GrayWindow toggle={props.toggle}>
            <div style={{ position: 'relative'}}>
            {showDeleteConfimration && <DeleteConfirmation onYes={deleteHandler} onNo={() => setShowDeleteConfirmation(false)}/>}
            {showTransfereWebsite && <TransferWebsite data={data} websiteToTransfer={websiteToTransfer} onClose={() => setShowTransfereWebsite(false)} closeWindow={props.toggle} updateData={props.updateData} forceReload={props.forceReload}/>}
            {showAddNewWebsite && <AddNewWebsite customerID={data.customerID} updateData={props.updateData} onClose={() => setShowAddNewWebsite(false)} closeWindow={props.toggle} forceReload={props.forceReload}/>}
            <div className={classes.main_top}>
                <span>{`Edit ${data.lastName} ${data.firstName} Websites`}</span>
                <div onClick={() => setShowAddNewWebsite(true)}><CustomButton name='Add New Website' color='blue' reverse/></div>
                <img src={exit} alt='X' onClick={props.toggle}/>
            </div>

            <div className={classes.data}>
                {data.websites.map((item,index) => {
                    return (
                    <div key={index} className={classes.item}>
                        <img className={classes.logo} onClick={() => navigateToWebsiteHandler(item.domain)} src={`https://static.foreshop.net/images/${item.domain}/logo.png`} alt='No Logo'></img>
                        <span className={classes.domain}>{item.domain}</span>
                        <span>{statusParser(item.status)}</span>
                        <div className={classes.buttons}>
                            <div className={classes.button} onClick={() => {setWebsiteToTransfer(item.websiteID) ; setShowTransfereWebsite(true);}}><IconContainerMedium image_src={transfere} color='blue'/></div>
                            <div className={classes.button} onClick={() => deleteConfirmationHandler(item.websiteID)}><IconContainerMedium image_src={delete_icon} color='red'/></div>
                        </div>
                    </div>
                    )
                })}
            </div>
            </div>
        </GrayWindow>
        </>
    )
}

export default EditUserWebsites;