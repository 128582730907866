import classes from './RecoverPassword.module.scss'

//import logo from '../../assets/images/foremedia.svg'
import foreshop from '../../assets/images/foreshop_logo.png'
import email_icon from '../../assets/icons/email.svg'
import email_red from '../../assets/icons/email_red.svg'
import email_blue from '../../assets/icons/email_blue.svg'
import email_green from '../../assets/icons/email_green.svg'

import CustomInput from '../Customs/CustomInput/CustomInput'
import CustomButton from '../Customs/CustomButton'

import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router'

const RecoverPassword = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [emailStatus, setEmailStatus] = useState('pending')
    const [emailIcon, setEmailIcon] = useState(email_icon)

    const [showMessage, setShowMessage] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    const emailHandler = (e) => {

        let value = e.target.value
        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

        if(value.length === 0) {
            setEmailIcon(email_icon)
        }
        else if(EMAIL_REGEX.test(value)) { 
            setEmailStatus('success')
            setEmailIcon(email_green)
        }
        else { 
            setEmailStatus('pending')
            setEmailIcon(email_blue)
        }
            
        setEmail(e.target.value)
    }

    const submitHandler = async (e) => {
        e.preventDefault()

        const request_options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: email})
        }

        if(emailStatus === 'success') {
            let response = await fetch(`${API_URL}/api/v1/recovery/sendrecoveryemail`,request_options)
            response = await response.json()
    
            if(response.sent) {
                setShowMessage(true)
    
                setTimeout(() => {
                    navigate('/login')
                },5000)
            }
            else {
                setEmailStatus('error')
                setEmailIcon(email_red)
            }
        }
        else {
            setEmailStatus('error')
            setEmailIcon(email_red)
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.main}>

                <div className={classes.top_container}>
                    <div className={classes.logo}><img src={foreshop} alt='ForeMedia'/></div>
                    <span className={classes.title}>Member Password Reset</span>
                </div>

                <div className={classes.form_container}>
                    <form className={classes.form} onSubmit={submitHandler}>
                        <div className={classes.form_item}>
                            <label htmlFor='email'>Type your Email</label>
                            <CustomInput image_src={emailIcon} placeholder='email@email.com' name='email' type='email' onChange={emailHandler} value={email} required status={emailStatus}/>
                            <div className={showMessage ? classes.message : classes.message_hidden}>Sent Succsesfully! Check your email</div>
                        </div>

                        <div className={classes.form_bottom}>
                            <NavLink className={classes.forgotpassword} to='/login'>Remember Password?</NavLink>
                            <div>
                                <CustomButton name='Reset Now' color='red' reverse type='submit'/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RecoverPassword;