import classes from './TranscationsTableItemUser.module.scss';

import { useSelector } from "react-redux";

import delete_icon from '../../../assets/icons/delete_red.svg'

import { fullYearParser } from "../../../utils/helper_functions"

const TransactionsTableItemUser = (props) => {


    const isAdmin = useSelector(state => state.user.isAdmin)
    const showAdminInterface = useSelector(state => state.ui.showAdminInterface)
    const api_token = useSelector(state => state.user.token)

    const data = props.data
    let added = fullYearParser(data.added*1000)
    let paid = data.paid

    const API_URL = process.env.REACT_APP_API_URL

    console.log(data)

    const deleteHandler = async () => {

        if(data.paid || data.paymentRequested) {
            alert('אי אפשר למחוק העברה שהתבקשה לתשלום')
            return;
        }

        let response = await fetch(`${API_URL}/api/v2/employees/transfers`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                transfers: [data.id]
            })
        })

        const res_status = response.status
        response = await response.json()

        if(res_status===200) {
            alert('העברה נמחקה בהצלחה')
            props.forceReload()
        }
    }

    return (
        <tr>
            <td className={classes.domain}>{data.domain}</td>
            <td>{data.type}</td>
            <td>{data.USD}</td>
            <td>{data.NIS}</td>
            <td>{data.nisRate}</td>
            <td>{added}</td>
            <td className={classes.count}>{data.count}</td>
            {paid && <td className={classes.paid}>{props.names.paid}</td>}
            {!paid && <td className={classes.notpaid}>{props.names.not_paid}</td>}
            {/* {(isAdmin && showAdminInterface) && <td><img style={{cursor: 'pointer', display:'flex'}} src={delete_icon} alt='delete' onClick={deleteHandler}/></td>} */}
        </tr>
    )
}

export default TransactionsTableItemUser;