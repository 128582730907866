import './App.css';
import Main from './components/Main/Main';
import Login from './components/Login/Login';
import LoginLoad from './components/LoginLoad/LoginLoad';
import RecoverPassword from './components/RecoverPassword/RecoverPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';

import {Route , Routes, Navigate } from 'react-router-dom'
import {useEffect, useState, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { userActions } from './store/user-slice';
import { uiActions } from './store/ui-slice';
import { TimeOutHandler, crispPushUserData, generateChat } from './utils/helper_functions';

function App() {

  const dispatch = useDispatch()
  const navigate = useNavigate ()
  const initPath = useRef(window.location.pathname)
  const isLogged = useSelector(state => state.ui.isLoggedIn)
  const navigateToCustomers = useSelector(state => state.ui.navigateToCustomers)
  const [loginProcess, setLoginProcess] = useState(false)

  const API_URL = process.env.REACT_APP_API_URL
  const PATH_NAME = window.location.pathname

  useEffect(() => {
      let token = localStorage.getItem('access_token')
      let role = localStorage.getItem('role')
    
      if(token && role) {
         setLoginProcess(true)
         LoginHandler(token,role)
      }
  },[isLogged])

  const LoginHandler = (token,role) => {

    let REQ_PATH = ['ADMIN','MODERATOR','OBSERVER'].includes(role) ? `${API_URL}/api/v2/employees/main` : `${API_URL}/api/v2/customers/main`

    fetch(REQ_PATH, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${token}`}
    })
    .then(val => {
      // Case user edited the values in the localStorage
      if(val.status !== 200) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        navigate('/login',{ replace: true })
        setLoginProcess(false)
      }
      // Case all values are correct
      else {
        return val.json()
      }
    })
    .then(data => {


      dispatch(userActions.setUser(data.result))
      dispatch(userActions.setIsAdmin(['ADMIN'].includes(role)))
      dispatch(userActions.setIsModerator(['ADMIN','MODERATOR'].includes(role)))
      dispatch(userActions.setIsObserver(['ADMIN','MODERATOR','OBSERVER'].includes(role)))
      dispatch(userActions.setToken(token))
      dispatch(uiActions.setIsLoggedIn(true))
      dispatch(uiActions.setShowAdminInterface(['ADMIN','MODERATOR','OBSERVER'].includes(role)))

      if(window.$crisp) {
        crispPushUserData(data.result)
        generateChat(role)
      }

      if(PATH_NAME === '/reset') {navigate('/reset',{ replace: true })}

      else {

        if(navigateToCustomers) {
          navigate('/user/customers',{ replace: true })
          TimeOutHandler(() => {setLoginProcess(false)})
        }
        else {

          if(!initPath.current.includes('login')) {
            navigate(initPath.current,{ replace: true })
            TimeOutHandler(() => {setLoginProcess(false)})
          }
          else {
            navigate('/',{ replace: true })
            TimeOutHandler(() => {setLoginProcess(false)})
          }
        }
      }

    })
  }

  return (
    <div className="app">
      {loginProcess && <LoginLoad />}
      <Routes>
        <Route path='/login' exact element={<Login setLoginProcess={setLoginProcess}/>}/>
        <Route path='/login/admin' exact element={<Login setLoginProcess={setLoginProcess} adminLogin/>}/>
        <Route path='/reset' element={<ResetPassword />}/>
        <Route path ='/recover-password' exact element={<RecoverPassword />} />
        <Route path='/' exact element={isLogged ? <Navigate to='/user/dashboard'/> : <Navigate to={'/login'}/>}/>
        <Route path='/:any' exact element={isLogged ? <Navigate to='/user/dashboard'/> : <div>404</div>}/>
        <Route path='/user/:page' element={isLogged ? <Main /> : <Navigate to='/login'/>}/>
      </Routes>
    </div>
  );
}

export default App;
