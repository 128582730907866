import classes from './Login.module.scss'

import foreshop from '../../assets/images/foreshop_logo.png'
import email_icon from '../../assets/icons/email.svg'
import email_red from '../../assets/icons/email_red.svg'
import email_blue from '../../assets/icons/email_blue.svg'
import email_green from '../../assets/icons/email_green.svg'
import lock from '../../assets/icons/lock.svg'
import lock_red from '../../assets/icons/lock_red.svg'
import lock_blue from '../../assets/icons/lock_blue.svg'
import lock_green from '../../assets/icons/lock_green.svg'

import CustomInput from '../Customs/CustomInput/CustomInput'
import CustomButton from '../Customs/CustomButton'

import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

import { useDispatch } from 'react-redux'
import { userActions } from '../../store/user-slice'
import { uiActions } from '../../store/ui-slice'

import { TimeOutHandler, crispPushUserData } from '../../utils/helper_functions'

const Login = (props) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [oneTimePassword, setOneTimePassword] = useState('')

    const [emailStatus, setEmailStatus] = useState('pending')
    const [passwordStatus, setPasswordStatus] = useState('pending')
    const [oneTimeStatus, setOneTimeStatus] = useState('pending')

    const [emailIcon, setEmailIcon] = useState(email_icon)
    const [passwordIcon, setPasswordIcon] = useState(lock)
    const [oneTimeIcon, setOneTimeIcon] = useState(lock)

    const dispatch = useDispatch()
    const navigate = useNavigate ()

    const API_URL = process.env.REACT_APP_API_URL

    const submitHandler = async (e) => {
        e.preventDefault()

        if(passwordStatus === 'success' && emailStatus === 'success') {

            props.setLoginProcess(true)

            const request_options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: email,
                    password: password,
                    otp: oneTimePassword
                })
            }

            let response = await fetch(`${API_URL}/api/v1/login`,request_options)
            let res_code = response.status
            response = await response.json()

            // bad username/password
            if(res_code === 400) {
                setEmailStatus('error')
                setEmailIcon(email_red)
                props.setLoginProcess(false)
                return;
            }
            // bad onetimepassword
            if(res_code === 401) {
                setOneTimeStatus('error')
                setOneTimeIcon(lock_red)
                props.setLoginProcess(false)
                return;
            }
            if(res_code === 200) {
                let STEP_TWO_URL = ['ADMIN','MODERATOR'].includes(response.role) ? `${API_URL}/api/v2/employees/main` : `${API_URL}/api/v2/customers/main`
                
                let step_two = await fetch(STEP_TWO_URL, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${response.access_token}`},
                })
                
                let userdata = await step_two.json()

                if(window.$crisp) crispPushUserData(userdata.result)

                localStorage.setItem('access_token', response.access_token)
                localStorage.setItem('role', response.role)
                dispatch(userActions.setUser(userdata.result))
                dispatch(userActions.setToken(response.access_token))
                dispatch(userActions.setIsAdmin(['ADMIN'].includes(response.role)))
                dispatch(userActions.setIsModerator(['ADMIN','MODERATOR'].includes(response.role)))
                dispatch(userActions.setIsObserver(['ADMIN','MODERATOR','OBSERVER'].includes(response.role)))
                dispatch(uiActions.setIsLoggedIn(true))
                dispatch(uiActions.setShowAdminInterface(['ADMIN','MODERATOR','OBSERVER'].includes(response.role)))
                navigate('/',{ replace: true })
            }

            TimeOutHandler(() => props.setLoginProcess(false))

        }

    }

    const emailHandler = (e) => {

        let value = e.target.value
        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

        if(value.length === 0) {
            setEmailIcon(email_icon)
        }
        else if(EMAIL_REGEX.test(value)) { 
            setEmailStatus('success')
            setEmailIcon(email_green)
        }
        else { 
            setEmailStatus('pending')
            setEmailIcon(email_blue)
        }
            
        setEmail(e.target.value)
    }

    const passwordHandler = (e) => {
        let value = e.target.value

        if(value.length === 0) {
            setPasswordIcon(lock)
        }
        else if(value.length >= 4) { 
            setPasswordStatus('success')
            setPasswordIcon(lock_green)
        }
        else { 
            setPasswordStatus('pending') 
            setPasswordIcon(lock_blue)
        }

        setPassword(value)
    }

    const oneTimePasswordHandler = (e) => {
        let value = e.target.value
        let ONLY_NUMBERS_REGEX = /^[0-9]*$/

        if(value.length === 0) {
            setOneTimeIcon(lock)
        }
        else if(!ONLY_NUMBERS_REGEX.test(value) || value.length > 6) {
            setOneTimeStatus('error')
            setOneTimeIcon(lock_red)
        }
        else {
            if(value.length === 6) {
                setOneTimeStatus('success')
                setOneTimeIcon(lock_green)
            }
            else {
                setOneTimeStatus('pending') 
                setOneTimeIcon(lock_blue)
            }
        }

        setOneTimePassword(value)
    }


    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.top_container}>
                    <div className={classes.logo}><img src={foreshop} alt='ForeMedia'/></div>
                    <span className={classes.title}>Member Login</span>
                </div>

                <div className={classes.form_container}>

                        <span className={classes.title}>Please sign in</span>

                        <form className={classes.form} onSubmit={submitHandler}>
                            <div className={classes.form_item}>
                                <label htmlFor='email'>Email</label>
                                <CustomInput image_src={emailIcon} placeholder='email@email.com' name='email' type='email' onChange={emailHandler} value={email} required status={emailStatus}/>
                            </div>
                            <div className={classes.form_item}>
                                <label htmlFor='password'>Password</label>
                                <CustomInput image_src={passwordIcon} placeholder='************' name='password' type='password' onChange={passwordHandler} value={password} required status={passwordStatus}/>
                            </div>
                            {props.adminLogin && 
                                <div className={classes.form_item}>
                                    <label htmlFor='onetime'>One Time Password</label>
                                    <CustomInput image_src={oneTimeIcon} placeholder='123456' name='onetime' type='password' onChange={oneTimePasswordHandler} value={oneTimePassword} required status={oneTimeStatus}/>
                                </div>                            
                            }
                            <div className={classes.form_bottom}>
                                <NavLink className={classes.forgotpassword} to='/recover-password'>Forgot Password?</NavLink>
                                <div>
                                    <CustomButton name='Login Now' color='red' reverse type='submit'/>
                                </div>
                            </div>
                        </form>

                        {/* <div className={classes.signup}>
                            <CustomButton name='Signup Now' color='blue'/>
                        </div> */}
                </div>
            </div>
        </div>
    )
}

export default Login