import React, {Component} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import classes from './LineChart.module.scss'

am4core.useTheme(am4themes_animated);

class LineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }
    chart() {
        am4core.options.autoDispose = true;
        am4core.options.minPolylineStep = 1;
        // am4core.options.deferredDelay = 500;
        am4core.options.onlyShowOnViewport = true;
        const { serverData } = this.props;
        let lineData = [];
        for (let i = 0; i < serverData.labels.length; i++) {
            lineData.push({ 'year': serverData.labels[i][0], 'income': 0 });
        }

        for (let i = 0; i < lineData.length; i++) {
            for (let x = 0; x < serverData.series[0].length; x++) {
                lineData[x].income = serverData.series[0][x];
            }
        }

        let chart = am4core.create("chartLine_" + this.props.title, am4charts.XYChart);
        chart.logo.disabled = true;
        chart.svgContainer.autoResize = true;
        chart.svgContainer.measure();
        chart.data = lineData;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.ticks.template.disabled = true;
        categoryAxis.renderer.line.opacity = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 40;
        categoryAxis.dataFields.category = "year";
        categoryAxis.startLocation = 0.4;
        categoryAxis.endLocation = 0.6;


        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.line.opacity = 0;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.min = 0;

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "year";
        lineSeries.dataFields.valueY = "income";
        lineSeries.tooltipText = "income: {valueY.value}";
        lineSeries.fillOpacity = 0.5;
        lineSeries.strokeWidth = 3;
        lineSeries.propertyFields.stroke = "lineColor";
        lineSeries.propertyFields.fill = "lineColor";

        let bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#fff");
        bullet.circle.strokeWidth = 3;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "panX";
        chart.cursor.lineX.opacity = 0;
        chart.cursor.lineY.opacity = 0;

        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.bottomAxesContainer;
    }

    componentDidMount() {
        this.chart();
    }
    componentDidUpdate() {
        this.chart();
    }

    render() {
        return (
            <div id={"chartLine_" + this.props.title} className={this.props.isAdmin ? classes.admin : classes.user}></div>
        );
    }
}

export default LineChart;
