 import classes from './Transactions.module.scss';

 import calander from '../../assets/icons/calander_blue.svg';
 import reset from '../../assets/icons/reset.svg'
 import calculator from '../../assets/icons/calculator.svg'

 import SearchBar from '../SearchBar/SearchBar';
 import AmountSelect from '../AmountSelect/AmountSelect';
 import Pagination from '../Pagination/Pagination';
 import TransactionsHeadersUser from './TransactionsHeadersUser';
 import TransactionsTableItemUser from './TransactionsTableItemUser/TranscationsTableItemUser';
 import FilterByDate from '../FilterByDate/FilterByDate';
 import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';
 import TransactionsCalculator from '../TransactionsCalculator/TranscationsCalculator';

 import { fullYearParser } from '../../utils/helper_functions';

 import { useSelector } from 'react-redux';
 import { useState, useEffect, useRef } from 'react';
 import useWindowSize from '../../assets/hooks/useWindowSize';

 const Transactions = () => {

    const names = useSelector(state => state.ui.language.values.transfersContent)
    const showAdminInterface = useSelector(state => state.ui.showAdminInterface)
    const api_token = useSelector(state => state.user.token)

    const [loadingResults, setLoadingResults] = useState(true)
    const [searchResults, setSearchResults] = useState(undefined)
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(20)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [moneyValues, setMoneyValues] = useState({USD: 0, NIS: 0})
    const numberOfEntries = useRef(0)

    const [Data, setData] = useState([])
    const [DataRendered, setDataRendered] = useState([])

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const [showFilterByDate, setShowFilterByDate] = useState(false)
    const [showTransfersCalculator, setShowTransfersCalculator] = useState(false)

    const [forceReload, setForceReload] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    let {width} = useWindowSize()

    useEffect( async () => {
        setLoadingResults(true)
        try {
            const response = await fetch(`${API_URL}/api/v2/users/transfers/list`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${api_token}`},
                body: JSON.stringify({
                    startDate: startDate,
                    endDate: endDate,
                    page: pageNumber,
                    items: numberOfResults,
                    search: searchResults
                })
            })
            const res_status = response.status
            let data = await response.json()

            if(data.result !== null && res_status === 200) {
                setMoneyValues(data.totals)
                setNumberOfPages(Math.ceil(data.entries/numberOfResults))
                numberOfEntries.current = data.entries
                setData(data.result)
                setDataRendered(data.result)
            }
            else {
                setData([])
                setDataRendered([])
                setNumberOfPages(1)
                setMoneyValues({USD: 0, NIS: 0})
            }
            setLoadingResults(false)
        }
        catch(err) {
            console.log(err)
        }

    },[pageNumber,numberOfResults,startDate,endDate,searchResults,forceReload])

    const searchResultsHandler = (e) => {
        setSearchResults(e.target.value)
    }

    const forceReloadHandler = () => {
        // value doesn't matter
        setForceReload(val => !val)
    }

    const toggleFilterByDate = () => {
        setShowFilterByDate(val => !val)
    }

    const toggleTransactionsCalculator = () => {
        setShowTransfersCalculator(val => !val)
    }

    const AmountSelectHandler = (e) => {
        setNumberOfResults(e.target.value)
        setPageNumber(1)

    }

    const resetDateFilter = () => {
        setStartDate('')
        setEndDate('')
    }

    return (
        <>
        {showFilterByDate && <FilterByDate toggle={toggleFilterByDate} setStart={setStartDate} setEnd={setEndDate} names={names}/>}
        {showTransfersCalculator && <TransactionsCalculator toggle={toggleTransactionsCalculator} numberOfEntries={numberOfEntries.current}/>}
        <div className={classes.container}>
            <div className={classes.revenue}>
                <div className={classes.revenue_title}>{`${names.filterRange} (${fullYearParser(startDate)} - ${fullYearParser(endDate)})`}</div>
                <div className={classes.revenue_values}>
                    <span>{`₪ ${moneyValues.NIS}`}</span>
                    <span>{`$ ${moneyValues.USD}`}</span>
                </div>
            </div>

            <div className={classes.main}>
                <div className={classes.main_top}>
                    <div className={classes.main_title}>{`${names.transactions}`}</div>
                    <div className={classes.main_buttons}>
                        <div className={classes.filter} onClick={resetDateFilter}>
                            <img src={reset} alt='reset'/>
                            {width > 600 && <span>{width < 800 ? `${names.buttons.reset}` : `${names.buttons.reset_filter}`}</span>}
                        </div>
                        <div className={classes.filter} onClick={toggleFilterByDate}>
                            <img src={calander} alt='dates'/>
                            {width > 600 && <span>{width < 800 ? `${names.buttons.filter}` : `${names.buttons.date_filter}`}</span>}
                        </div>
                        {/* {!showAdminInterface &&
                        <div className={classes.filter} onClick={toggleTransactionsCalculator}>
                            <img src={calculator} alt='dates'/>
                            {width > 600 && <span>{width < 800 ? `${names.buttons.calculator}` : `${names.buttons.trasnfers_calculator}`}</span>}
                         </div>                        
                        } */}
                    </div>
                </div>

                <div className={classes.main_middle}>
                    {/* <SearchBar placeholder={'Search by domain'} value={searchResults} onChange={searchResultsHandler}/> */}
                    <div></div>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={AmountSelectHandler}/>
                </div>
                {(width < 900 && numberOfPages > 1) &&
                <div className={classes.mobile_pagination}>
                    <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>
                </div>
                }

                <ResponsiveTable loadingResults={loadingResults}>
                    <thead>
                        <TransactionsHeadersUser names={names}/>
                    </thead>
                    <tbody className={classes.table_data}>
                        {DataRendered.map((item,index) => <TransactionsTableItemUser data={item} key={index} names={names} forceReload={forceReloadHandler}/>)}
                    </tbody>
                </ResponsiveTable>

                {width > 900 &&
                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={AmountSelectHandler}/>
                    {numberOfPages > 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>                
                }
            </div>
        </div>
        </>
    )

 }

 export default Transactions