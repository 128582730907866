import classes from './FinalApprovment.module.scss'

import PopUpCard from "../../Cards/PopUpCard/PopUpCard"
import ResponsiveTable from "../../Customs/ResponsiveTable/ResponsiveTable"
import CustomButton from '../../Customs/CustomButton'
import CheckAnimation from '../../CheckAnimation/CheckAnimation'

import FinalApprovmentTableItem from './FinalApprovmentTableItem'
import PaymentInvoiceContainer from '../../PaymentInvoiceContainer/PaymentInvoiceContainer'

import { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import { TimeOutHandler } from '../../../utils/helper_functions'

const FinalApprovment = (props) => {

    const role = props.role
    const names = props.names
    const api_token = useSelector(state => state.user.token)

    const [loadingResults, setLoadingResults] = useState(true)
    const [data, setData] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [stack, setStack] = useState([]) 

    const [showSelectedRequests, setShowSelectRequests] = useState(false)
    const [showInvoiceContainer, setShowInvoiceContainer] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [invoiceSrc, setInvoiceSrc] = useState('')

    const API_URL = process.env.REACT_APP_API_URL


    useEffect(async () => {
        
        if(!showSelectedRequests) {
            setLoadingResults(true)
            const response = await fetch(`${API_URL}/api/v2/getpaymentrequests`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${api_token}`},
                body: JSON.stringify({
                    request_status: role === 'COO' ? 'request accepted' : 'payment process'
                })
            })
    
            const data = await response.json()
            if(response.status === 200) {
                setData(data.result)
                setLoadingResults(false)
                
            }
        }

    },[showSelectedRequests])

    const submitHandler = async () => {
        let res = await updateStatusHandler()
        
        if(role === 'CFO') {
            res = await updateTranscationsToPaid()
        }
        if(res === 200) {
            setShowCheckAnimation(true)
            TimeOutHandler(() => {
                props.forceUpdate()
                props.toggle()
            })
        }
    }

    const updateStatusHandler = async () => {

        const date = new Date().toISOString()

        let response = await fetch(`${API_URL}/api/v2/paymentsfinals`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestIDs: stack,
                request_status: role === 'COO' ? 'payment process' : 'paid',
                approved_date: date
            })
        })

        const res_status = response.status
        response = await response.json()

        return res_status
    }

    const updateTranscationsToPaid = async () => {

        let transferslist = []

        data.forEach((item) => {
            if(stack.includes(item.paymentRequestID)) {
                transferslist = [...transferslist,...item.payments_transfers]
            }
        })

        let response = await fetch(`${API_URL}/api/v2/employees/transfersreset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestIDs: stack
            })
        })

        let res_status = response.status
        response = response.json()

        return res_status

    }

    const selectAllHandler = (e) => {
        const value = e.target.checked

        if(value) {
            setSelectAll(true)
            dataToPaymentsID()
        }
        else {
            setSelectAll(false)
            setStack([])
        }
    }

    const showSelectedRequestsHandler = () => {
        setLoadingResults(true)
        setSelectAll(false)
        TimeOutHandler(() => {
            const selectedRequests = data.filter((req) => stack.includes(req.paymentRequestID))
            console.log(selectedRequests)
            setData(selectedRequests)
            setShowSelectRequests(true)
            setLoadingResults(false)
        },1000)

        
    }

    const dataToPaymentsID = () => {
        const new_array = data.map((item) => item.paymentRequestID)
        setStack(new_array)
    }

    const showInvoiceHandler = (image_src) => {
        setInvoiceSrc(image_src)
        setShowInvoiceContainer(true)
    }

    
    return (
        <>
        {showInvoiceContainer && <PaymentInvoiceContainer image_src={invoiceSrc} toggle={() => setShowInvoiceContainer(val => !val)}/>}
        <PopUpCard header={`אישורים אחרונים - ${role}`} toggle={props.toggle}>
            {showCheckAnimation && <CheckAnimation/>}
            <ResponsiveTable loadingResults={loadingResults}>
                <thead>
                    <tr>
                        {(!showSelectedRequests) && <th style={{width: '50px', minWidth: 'unset'}}><input type='checkbox' value={selectAll} onChange={selectAllHandler}/></th>}
                        <th>{names.client_name}</th>
                        <th>{names.date}</th>
                        <th>{'₪ ' + names.amount}</th>
                        <th>{names.receipt}</th>
                        <th>{names.receipt_status}</th>
                        <th style={{width: 200}}>{names.receipt_tax}</th>
                        <th>{names.client_check}</th>
                        <th style={{width: 200}}>{names.receipt_tax}</th>
                        <th>{names.notes}</th>
                    </tr>
                </thead>
                <tbody className={classes.table_data}>
                    {data.map((item,index) => <FinalApprovmentTableItem key={index} names={names} data={item} selectAll={selectAll} setStack={setStack} stack={stack} onShowInvoice={showInvoiceHandler} showSelectedRequests={showSelectedRequests} role={role}/>)}
                </tbody>
            </ResponsiveTable>
            {!showSelectedRequests &&
                <div className={classes.buttons}>
                    <span className={classes.cancel} onClick={props.toggle}>חזרה אחורה</span>
                    <div><CustomButton name='הצג בקשות שנבחרו' onClick={showSelectedRequestsHandler}/></div>
                </div>    
            }
            {showSelectedRequests &&
            <div className={classes.buttons}>
                <span className={classes.cancel} onClick={() => {setShowSelectRequests(false); setStack([])}} >חזרה אחורה</span>
                <div><CustomButton name='שלח' color='blue' reverse onClick={submitHandler}/></div>
            </div>            
            }
        </PopUpCard>
        </>
    )
}

export default FinalApprovment;