import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { monthsTranslator } from "../../../utils/helper_functions";

const PaymentUserRequestTableItem = (props) => {

    const [isChecked, setIsChecked] = useState(props.checked)
    const language = useSelector(state => state.ui.language.code)
    const item = props.item
    const month = monthsTranslator(item.month,language)

    useEffect(() => {
        setIsChecked(props.checked)
        if(props.checked) {
            props.updateTotalMoney(item.sum.NIS)
            props.updateTransferList(item.transfers)
        }
    },[props.checked,setIsChecked])

    const onChangeHandler = () => {
        props.updateTotalMoney(item.sum.NIS)
        props.updateTransferList(item.transfers)

        setIsChecked(val => !val)
    }

    return (
        <tr style={{textAlign: 'center'}}>
            <td style={{width: '10px'}}><input type='checkbox' checked={isChecked} onChange={onChangeHandler}/></td>
            <td>{month}</td>
            <td>{item.year}</td>
            <td>{'₪ '+item.sum.NIS.toFixed(2)}</td>
        </tr>    )
}

export default PaymentUserRequestTableItem;