import classes from './PaginationItem.module.scss'


const PaginationItem = (props) => {

    let styles = [classes.container,classes.default].join(' ')

    if(props.image_src || props.current) {
        styles = [classes.container,classes.red].join(' ')
    }


    return (
        <div className={styles} onClick={props.onClick}>
            {props.image_src && <img src={props.image_src} alt={props.image_alt}/>}
            {!props.image_alt && <span>{props.number}</span>}
        </div>
    )

}

export default PaginationItem