import classes from './ConfirmationStatusItem.module.scss';

import { useState } from "react";
import { useSelector } from 'react-redux';

const ConfimationStatusItem = (props) => {

    const personalStatus = props.status[props.name]
    let styles = [classes.container,classes.default].join(' ')


    if(personalStatus === true) styles = [classes.container,classes.green].join(' ')
    if(personalStatus === false) styles = [classes.container,classes.red].join(' ')

    const onClickHandler = () => {
        if(props.permissions.all) props.confirmWindowHandler()
        else {
            if(props.permissions.extra_roles.includes(props.name)) props.confirmWindowHandler()
        }
    }


    return (
        <div className={styles} key={props.forKey} onClick={onClickHandler}>
            {props.name}
        </div>
    )

}

export default ConfimationStatusItem;