import classes from './OnAirTableItem.module.scss'

import delete_icon from '../../assets/icons/delete_red.svg'

import { fullYearParser, OnAirStatusObjectsCombine } from "../../utils/helper_functions"

import ConfimationStatusItem from "./ConfirmationStatusItem/ConfirmationStatusItem"
import EditAndSave from '../Cards/EditAndSave/EditAndSave'

import { useState, useEffect } from "react"
import { useSelector } from 'react-redux'

const OnAirTableItem = (props) => {

    const STATUS_ITEMS_LIST = ['ads','articles','campaigns','product','seo','social']
    const data = props.data

    const user_permissions = useSelector(state => state.user.permissions)
    const api_token = useSelector(state => state.user.token)

    const [status, setStatus] = useState(OnAirStatusObjectsCombine(data.confirmations, data.declines))
    const [approved, setApproved] = useState(!data.pending)
    const [lineStatusStyle, setLineStatusStyle] = useState([classes.line_status,classes.waiting].join(' '))
    const [note, setNote] = useState(data.additionalNote)

    const [showEditAndSave, setShowEditAndSave] = useState(false)

    const start_date = fullYearParser(data.start)

    const API_URL = process.env.REACT_APP_API_URL

    useEffect(() => {

        if(status.ads && status.articles && status.campaigns && status.product && status.seo && status.social) {
            setApproved(true)
            setLineStatusStyle([classes.line_status,classes.approved].join(' '))
        }
        else {
            setApproved(false)
            setLineStatusStyle([classes.line_status,classes.waiting].join(' '))
        }

    },[status])

    const toggleShowEditAndSave = () => {
        setShowEditAndSave(val => !val)
    }

    const updateNoteHandler = async (note) => {

        let response = await fetch(`${API_URL}/api/v2/campaigns/action`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                additionalNote: note,
                campaignID: data.id
            })
        })
        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            setNote(note)
            setShowEditAndSave(false)
        }
    }

    return (
        <>
        <tr onMouseLeave={() => setShowEditAndSave(false)}>
            <td><div className={lineStatusStyle} onClick={!approved ? props.onStatusClick : () => {}}>{approved ? 'Approved' : 'Waiting for approval'}</div></td>
            <td>{start_date}</td>
            <td className={classes.status}>{STATUS_ITEMS_LIST.map((name) => <ConfimationStatusItem 
                                                                            key={name} name={name} 
                                                                            status={status} 
                                                                            setStatus={setStatus} 
                                                                            permissions={user_permissions} 
                                                                            id={data.id} 
                                                                            confirmWindowHandler={() => props.confirmWindowHandler({data: props.data, name: name})}/>)}
            </td>
            <td className={classes.email}>{data.customer.email}</td>
            <td className={classes.retainer}>{data.retainer}</td>
            <td style={{position: 'relative', cursor: 'pointer'}} onClick={() => toggleShowEditAndSave()}>
                <p className={classes.note}>{note}</p>
                {showEditAndSave && <EditAndSave onSubmit={updateNoteHandler} note={note}/>}
            </td>
            <td className={classes.delete}>
                <img src={delete_icon} alt='delete' onClick={props.onDelete}/>
            </td>
        </tr>
        </>
    )

}

export default OnAirTableItem;