import classes from '../Children.module.scss';

import exit from '../../../assets/icons/exit.svg'

import CustomSelect from '../../Customs/CustomSelect/CustomSelect';
import SearchBar from '../../SearchBar/SearchBar';
import CustomButton from '../../Customs/CustomButton';
import LoadingTable from '../../LoadingTable/LoadingTable';
import CheckAnimation from '../../CheckAnimation/CheckAnimation';
import FailAnimation from '../../FailAnimation/FailAnimation';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TimeOutHandler } from '../../../utils/helper_functions';


const TransferWebsite = (props) => {

    const [customerList, setCustomerList] = useState([])
    const [renderedCustomerList, setRenderedCustomerList] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState('')

    const [searchResults, setSearchResults] = useState('')
    const [showSpinner, setShowSpinner] = useState(true)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation , setShowFailAnimation] = useState(false)

    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL

    const data = props.data

 
    useEffect(async() => {

        await importCustomerList()
        setShowSpinner(false)

    },[])

    const importCustomerList = async () => {
        let request_options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
        }

        const clients = await fetch(`${API_URL}/api/v2/campaigns/clients`,request_options)
        const clients_data = await clients.json()
        const renderedList = clients_data.map((item) => `${item.email} | ${item.lastName} ${item.firstName}`)

        setCustomerList(clients_data)
        setRenderedCustomerList(renderedList)
        setSelectedCustomer(renderedList[0])
    }

    const SearchResultHandler = (value) => {

        if(value === '') {
            const initList = customerList.map((item) => `${item.email} | ${item.lastName} ${item.firstName}`)
            setRenderedCustomerList(initList)
            setSelectedCustomer(initList[0])
            
        }
        else {
            const filtered_list = customerList.filter((item) => item.email?.startsWith(value) || item.firstName?.startsWith(value) || item.lastName?.startsWith(value))
            const rendered_list = filtered_list.map((item) => `${item.email} | ${item.lastName} ${item.firstName}`)
            setRenderedCustomerList(rendered_list)
            setSelectedCustomer(rendered_list[0])
        }

        setSearchResults(value)
    }

    const getDestinationCustomerID = () => {
        let email = selectedCustomer.split('|')[0].trim()
        const customer = customerList.find((item) => item.email === email)
        return customer.id
    }

    const removeWebsiteFromUser = async(headers) => {


        let removeWebsite = await fetch(`${API_URL}/api/v2/employees/websites/status`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                customerID: data.customerID,
                status: 'CUSTOMER_LEFT',
                websiteID: props.websiteToTransfer
            })
        })
        let remove_status = removeWebsite.status
        removeWebsite = await removeWebsite.json()

        return remove_status
    }

    const AddWebsiteToUser = async(headers,reciving_customer) => {

        const customerID = getDestinationCustomerID()

        let addWebsite = await fetch(`${API_URL}/api/v2/employees/websites/status`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                customerID: customerID,
                status: 'CUSTOMER_ACTIVE',
                websiteID: props.websiteToTransfer
            })
        })
        let add_status = addWebsite.status
        addWebsite = await addWebsite.json()

        return add_status
    }

    const revertChanges = async(headers) => {

        let revertChanges = await fetch(`${API_URL}/api/v2/employees/websites/status`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                customerID: data.customerID,
                status: 'CUSTOMER_ACTIVE',
                websiteID: props.websiteToTransfer
            })
        })
        let revert_status = revertChanges.status
        revertChanges = await revertChanges.json()

        return revert_status

    }

    const TransferSubmitHandler =  async () => {

        const reciving_customer = customerList.filter((client) => client.email === selectedCustomer)
        const headers = {'Content-Type': 'application/json','authorization': `Bearer ${api_token}`}
        
        // Remove Website from the user which we selected on Customers main page
        const remove_status = await removeWebsiteFromUser(headers)

        if(remove_status === 200) {
        // Add Website to the user which we seleted to transfer
        const add_status = await AddWebsiteToUser(headers,reciving_customer)

            if(add_status === 200) { 
                let filtered_websites = data.websites.filter((website) => website.websiteID !== props.websiteToTransfer)
                // props.updateData((item) => { return {...item, websites: filtered_websites}})
                props.forceReload()
                setShowCheckAnimation(true)
                TimeOutHandler(() => props.closeWindow(),3000)
            }
            // Case Add step went wrong, we will revert the changes on Remove
            else {
            const revert_status = await revertChanges(headers)
                setShowFailAnimation(true)
                TimeOutHandler(() => setShowFailAnimation(false), 3000)

                if(revert_status !== 200) {
                    alert('something went wrong with the revertion, tell the developer')
                }
            }
        }
    }

    return (
        <div className={classes.container}>
            {showSpinner && <LoadingTable />}
            {!showSpinner &&
            <>
            <div className={classes.main_top}>
                    <span>{'Transfer Website'}</span>
                    <img src={exit} alt='X' onClick={props.onClose}/>
            </div>   

            <div className={classes.main_content}>
                {showCheckAnimation && <CheckAnimation />}
                {showFailAnimation && <FailAnimation />}
                <div className={classes.select}>
                    <SearchBar placeholder='Search by Customer' onChange={(e) => SearchResultHandler(e.target.value)} value={searchResults} autoFocus/>
                    <CustomSelect value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.target.value)}>
                        {renderedCustomerList.map((item,index) => <option key={index} value={item}>{item}</option>)}
                    </CustomSelect>
                </div>

                <div className={classes.buttons}>
                    <div className={classes.cancel} onClick={props.onClose}>Cancel</div>
                    <div onClick={TransferSubmitHandler}><CustomButton name={'Transfer Website'} color='blue' reverse/></div>
                </div>
            </div>         
            </>
            }

        </div>

    )
}

export default TransferWebsite;