import classes from '../PaymentsUser/PaymentUserTableItem/PaymentUserTableItem.module.scss'

import approve from '../../assets/icons/approved.svg'
import disapproved from '../../assets/icons/disapproved.svg'
import questionmark from '../../assets/icons/questionmark.svg'

import EditAndSave from '../Cards/EditAndSave/EditAndSave'

import { fullYearParser, paymentsStatusesHandler } from "../../utils/helper_functions";

import { useSelector } from 'react-redux';
import { useState } from 'react';

const PaymentAdminTableItem = (props) => {

    const data = props.data
    const names = props.names
    const language = useSelector(state => state.ui.language.code)
    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL


    const [notes, setNotes] = useState(data.notes)
    const [showEditAndSave, setShowEditAndSave] = useState(false)

    const isClickable = data.request_status === 'processing' || data.request_status === 'request accepted' || data.request_status ==='request declined' || data.request_status === 'paid'
    let added = fullYearParser(data.created)

    const showEditAndSaveToggle = () => {setShowEditAndSave(val => !val)}

    const updateNotesHandler = async (note) => {

        let response = await fetch(`${API_URL}/api/v2/paymentrequestcontroller`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestID: data.paymentRequestID,
                notes: note
            })
        })

        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            setNotes(note)
            setShowEditAndSave(false)
        }
    }

    const statusClassHandler = () => {

        if(data.request_status === 'processing') return classes.processing
        if(data.request_status === 'request accepted') return classes.request_accepted
        if(data.request_status === 'request declined') return classes.request_denied
        if(data.request_status === 'payment process') return classes.payment_process
        if(data.request_status === 'paid') return classes.paid
    }

    let status_class = statusClassHandler(data.request_status)

    return (
        <tr style={{textAlign: 'center'}} onMouseLeave={() => setShowEditAndSave(false)}>
            
            <td className={status_class}
            style={{cursor: isClickable ? 'pointer' : 'default', fontWeight: '500'}}
            onClick={isClickable ? props.onRejectApprove : () => {}}>
                {paymentsStatusesHandler(data.request_status,language)}
            </td>
            
            <td>{`${data.customer.firstName} ${data.customer.lastName}`}</td>
            <td>{added}</td>
            <td>{`₪ ${data.amount.NIS}`}</td>
            <td style={{color: 'green', cursor: 'pointer', fontWeight: '500', width: 120}} onClick={() => props.onShowInvoice(data.receipt_img[0],data)}>{names.view}</td>
            <td>
                <div style={{cursor: 'pointer', display: 'flex', justifyContent:'center', alignItems: 'center'}} onClick={props.onChangeStatus}>
                {(data.receipt_status === true) && <img src={approve} alt='approve'/>}
                {(data.receipt_status === false && data.refuse_reason.receipt_status_reason !== '') && <img src={disapproved} alt='rejected'/>}
                {(data.refuse_reason.receipt_status_reason === '' && !data.receipt_status) && <img style={{width: 21}} src={questionmark} alt='pending'/>}
                </div>
            </td>
            <td>
                <div style={{cursor: 'pointer', display: 'flex', justifyContent:'center', alignItems: 'center'}} onClick={props.onChangeStatus}>
                {(data.client_check === true ) && <img src={approve} alt='approve'/>}
                {(data.client_check === false && data.refuse_reason.client_check_reason !== '') && <img src={disapproved} alt='rejected'/>}
                {(data.refuse_reason.client_check_reason === '' && !data.client_check) && <img style={{width: 21}} src={questionmark} alt='pending'/>}
                </div>
            </td>
            <td>
                <div style={{cursor: 'pointer', display: 'flex', justifyContent:'center', alignItems: 'center'}} onClick={props.onChangeStatus}>
                {(data.receipt_tax_invoice === true) && <img src={approve} alt='approve'/>}
                {(data.receipt_tax_invoice === false && data.refuse_reason.receipt_tax_invoice_reason !== '') && <img src={disapproved} alt='rejected'/>}
                {(data.refuse_reason.receipt_tax_invoice_reason === '' && !data.receipt_tax_invoice) && <img style={{width: 21}} src={questionmark} alt='pending'/>}
                </div>
            </td>
            <td>
                {data.invoice_img[0] && <div style={{color: 'green', cursor: 'pointer', fontWeight: '500'}} onClick={() => props.onShowInvoice(data.invoice_img[0],data)}>{names.view}</div>}
                {!data.invoice_img[0] && <div style={{color: 'red', fontWeight: '500', cursor: 'not-allowed'}}>{names.not_available}</div>}
            </td>
            <td>
                <div style={{cursor: 'pointer', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                {!data.charge_refund && <img style={{width: 21}} src={questionmark} alt='pending' onClick={props.onChargeRefund}/>}
                {data.charge_refund && <img src={approve} alt='approve' onClick={props.onChargeRefund}/>}
                </div>
            </td>
            <td style={{position: 'relative'}} onClick={showEditAndSaveToggle}>
                {showEditAndSave && <EditAndSave onSubmit={updateNotesHandler} note={notes}/>}
                {notes}
            </td>
        </tr>
    )

}

export default PaymentAdminTableItem;