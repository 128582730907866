import classes from './RefuseReasons.module.scss'

import PopUpCard from "../../Cards/PopUpCard/PopUpCard";

const RefuseReasons = (props) => {

    const refuse_reason = props.data.refuse_reason
    const names = props.names


    return (
        <PopUpCard header='סיבות סירוב' toggle={props.toggle}>
            <div className={classes.main}>
                {refuse_reason.receipt_status_reason &&
                <div className={classes.refuseitem}>
                    <div className={classes.refuseitem_title}>{`:${names.receipt_status}`}</div>
                    <div className={classes.refuseitem_paragraph}>{refuse_reason.receipt_status_reason}</div>
                </div>                
                }
                {refuse_reason.client_check_reason &&
                <div className={classes.refuseitem}>
                    <div className={classes.refuseitem_title}>{`:${names.client_check}`}</div>
                    <div className={classes.refuseitem_paragraph}>{refuse_reason.client_check_reason}</div>
                </div>                
                }
                {refuse_reason.receipt_tax_invoice_reason &&
                <div className={classes.refuseitem}>
                    <div className={classes.refuseitem_title}>{`:${names.receipt_tax}`}</div>
                    <div className={classes.refuseitem_paragraph}>{refuse_reason.receipt_tax_invoice_reason}</div>
                </div>                
                }

            </div>
        </PopUpCard>
    )
}

export default RefuseReasons;