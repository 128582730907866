import classes from './PaymentInvoiceContainer.module.scss'

import GrayWindow from '../Cards/GrayWindow/GrayWindow'
import LoadingTable from '../LoadingTable/LoadingTable'
import Pagination from '../Pagination/Pagination'

import exit from '../../assets/icons/exit.svg'

import { Document, Page, pdfjs } from 'react-pdf';

import { useState } from 'react'

import testpdf from '../../assets/documents/Terms.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const PaymentInvoiceContainer = (props) => {

   const [isLoading, setIsLoading] = useState(true)
   const [pdfNumberOfPages, setPdfNumberOfPages] = useState(1)
   const [pdfCurrentPage, setPdfCurrentPage] = useState(1)
   const isPDF = props.image_src.includes('.pdf')
   const data = props.data
   const isCompany = props?.data?.customer.paymentDetails.legal_status === 'Company'

   const loadedHandler = (pdf) => {

    setIsLoading(false)
    if(pdf) {
        setPdfNumberOfPages(pdf._pdfInfo.numPages)
    }
   }


    return (
        <GrayWindow toggle={props.toggle} layerTwo>
            <div className={classes.container}>
                {isLoading && <div className={classes.loading}><LoadingTable /></div>}
                <img className={classes.exit} src={exit} alt='X' onClick={props.toggle}/> 
                {(!isLoading && isCompany && data) &&
                <div className={classes.money}> 
                    <span>₪ {(data.amount.NIS*0.87).toFixed(2)} :סכום לא כולל מע"מ</span>
                    <span>₪ {(data.amount.NIS*0.13).toFixed(2)} :מע"מ</span>
                    <span style={{fontWeight: 'bolder'}}>₪ {data.amount.NIS} :סכום כולל מע"מ</span>
                </div>
                }
                {(!isLoading && !isCompany && data) &&
                <div className={classes.money}> 
                    <span style={{fontWeight: 'bolder'}}>₪ {data.amount.NIS} :סה"כ</span>
                </div>
                }
                {!isPDF && <img src={props.image_src} onClick={() => window.print()} onLoad={loadedHandler}/>}
                {isPDF &&
                <div style={{cursor: 'pointer'}} onClick={() => window.open(props.image_src, '_blank')}>
                <Document className={classes.pdf} loading='' file={{url: props.image_src, httpHeaders: {'Content-type': 'application/pdf'}}} onLoadSuccess={loadedHandler} onLoadError={(err) => {console.error(err); loadedHandler()}}>
                    <Page  pageNumber={pdfCurrentPage}/>
                </Document>
                </div>
                }
                {(isPDF && !isLoading && pdfNumberOfPages > 1) && <Pagination numberOfPages={pdfNumberOfPages} pageNumber={pdfCurrentPage} setPageNumber={setPdfCurrentPage}/>}
            </div>
        </GrayWindow>
    )
}

export default PaymentInvoiceContainer;