import NavBar from "../NavBar/NavBar"
import NavBarMobile from "../NavBarMobile/NavBarMobile"
import TopBar from "../TopBar/TopBar"
import TopBarMobile from "../TopBarMobile/TopBarMobile"
import Dashboard from "../Dashboard/Dashboard"
import WebsiteControl from "../WebsitesControl/WebsiteControl"
import CustomerWebsites from "../CustomerWebsites/CustomerWebsites"
import OnAir from "../OnAir/OnAir"
import Transactions from "../Transactions/Transcations"
import MyProfile from "../MyProfile/MyProfile"
import PaymentUser from "../PaymentsUser/PaymentUser"
import PaymentAdmin from "../PaymentsAdmin/PaymentAdmin"
import Customers from "../Customers/Customers"
import Logs from '../Logs/Logs'

import UserAgreement from "../UserAgreement/UserAgreement"
import Message from "../Message/Message"
import SystemMessages from "../SystemMessages/SystemMessages"

import { useParams } from 'react-router-dom'
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { useState } from "react"

import useWindowSize from '../../assets/hooks/useWindowSize'

const Main = () => {

    const params = useParams()
    const page = params.page

    const isEmployee = useSelector(state => state.user.isObserver)
    const isImpersonating = useSelector(state => state.ui.isImpersonating)
    const showAdminInterface = useSelector(state => state.ui.showAdminInterface)
    const showNavBarMobile = useSelector(state => state.ui.showNavBarMobile)
    const agreement = useSelector(state => state.user.agreement)
    const declinedPayments = useSelector(state => state.user.declinedPayments)

    const [showAgreement, setShowAgreement] = useState((isEmployee || isImpersonating) ? false : !agreement)

    const messageID = 'msg_id:1' // Please Change Each time you change the Message Body
    const seen_message = (localStorage.getItem(messageID) === 'true')
    const [showMessage, setShowMessage] = useState((isEmployee || isImpersonating) ? false : !seen_message) // Message is for general messages

    const [showSystemMessages, setShowSystemMessages] = useState(!isEmployee && declinedPayments.length > 0) // System Messages are only in case they have refused payments or other important things.

    let {width} = useWindowSize()

    const validRoutes = ['dashboard','transactions','profile','websites','onair','payments','customers','logs']

    return (<>
        {width > 500 && <NavBar/>}
        {width > 500 ? <TopBar /> : <TopBarMobile />}
        {showNavBarMobile && <NavBarMobile />}
        {showAgreement && <UserAgreement showAgreement={() => setShowAgreement(false)}/>}
        {/* {showMessage && <Message messageID={messageID} setShowMessage={() => setShowMessage(false)}/>} */}
        {(showSystemMessages && !showMessage) && <SystemMessages setShowSystemMessages={() => setShowSystemMessages(false)} data={declinedPayments}/>}
        {page === 'dashboard' && <Dashboard />}
        {page === 'transactions' && <Transactions />}
        {page === 'profile' && <MyProfile />}
        {(page === 'websites' && showAdminInterface) && <WebsiteControl />}
        {(page === 'websites' && !showAdminInterface) && <CustomerWebsites />}
        {(page === 'onair' && showAdminInterface) && <OnAir />}
        {(page === 'onair' && !showAdminInterface) && <Navigate to='/'/>}
        {(page === 'payments' && showAdminInterface) && <PaymentAdmin />}
        {(page === 'payments') && !showAdminInterface && <PaymentUser />}
        {(page === 'customers' && showAdminInterface) && <Customers />}
        {(page === 'customers' && !showAdminInterface) && <Navigate to='/'/>}
        {(page === 'logs' && showAdminInterface) && <Logs />}
        {(page === 'logs' && !showAdminInterface) && <Navigate to='/'/>}
        {!validRoutes.includes(page) && <Navigate to='/'/>}
        </>
    )
}

export default Main