import classes from './SystemMessages.module.scss'

import PopUpCard from "../Cards/PopUpCard/PopUpCard";
import CustomButton from '../Customs/CustomButton';

import { fullYearParser } from '../../utils/helper_functions';
const SystemMessages = (props) => {

    const data = props.data
    
    return (
        <PopUpCard header='הודעות מערכת' toggle={props.setShowSystemMessages}>
            <div className={classes.main}>
            <div className={classes.textcontent}>
                <ul>
                    {data.map((item, index) => {
                        return (
                            <li key={index}>
                            <div className={classes.list_item}>
                                <span style={{fontWeight: 'bolder'}}>{`:לקוח יקר בקשתך לתשלום בתאריך ${fullYearParser(item.created)} סורבה עקב`}</span>
                                {!item.receipt_status && <span>{`${item.refuse_reason.receipt_status_reason}`}</span>}
                                {!item.receipt_tax_invoice && <span>{`${item.refuse_reason.receipt_tax_invoice_reason}`}</span>}
                                {!item.client_check && <span>{`${item.refuse_reason.client_check_reason}`}</span>}
                            </div>
                        </li>
                        )
                    })}
                </ul>
            </div>
            <div className={classes.button}><CustomButton name='אשר' reverse color='blue' onClick={props.setShowSystemMessages}/></div>
            </div>
        </PopUpCard>
    )

}

export default SystemMessages;