import React, {Component} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    chart() {
        am4core.options.autoDispose = true;
        am4core.options.minPolylineStep = 1;
        // am4core.options.deferredDelay = 100;
        am4core.options.onlyShowOnViewport = true;
        const { serverData } = this.props;

        let barData = [];
        for (let i = 0; i < serverData.labels.length; i++) {
            barData.push({ 'year': serverData.labels[i], 'income': 0 });
        }


        for (let i = 0; i < barData.length; i++) {
            for (let x = 0; x < serverData.series[0].length; x++) {
                barData[x].income = serverData.series[0][x];
            }
        }
        barData.sort((a,b) => {
            if(a.income > b.income){
                return -1
            }else if(a.income < b.income){
                return 1
            }else{
                return 0
            }
        })
        let chart = am4core.create("chartBar_" + this.props.title, am4charts.XYChart);
        chart.logo.disabled = true;
        chart.svgContainer.autoResize = true;
        chart.svgContainer.measure();
        chart.data = barData;

        //create category axis for years
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 25;

        //create value axis for income and expenses
        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
		valueAxis.min = 0;

        //create columns
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "year";
        series.dataFields.valueX = "income";
        series.name = "Income";
        series.columns.template.fillOpacity = 0.8;
        series.columns.template.strokeOpacity = 0;
        series.tooltipText = "{valueX.value}";

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;



        chart.cursor = new am4charts.XYCursor();
        // chart.cursor.lineY.disabled = true;
        // chart.cursor.lineX.disabled = false;
        chart.cursor.behavior = "zoomX";

        //add legend
        // chart.legend = new am4charts.Legend();
        // let scrollBar = new am4charts.XYChartScrollbar();
        // scrollBar.series.push(series);
        // chart.scrollbarY = scrollBar;

    }

    componentDidMount() {
        this.chart();
    }
    componentDidUpdate() {
        this.chart();
    }

    render() {
        return (
            <div id={"chartBar_" + this.props.title} style={{ width: "100%", height: "220px" }}></div>
        );
    }
}

export default BarChart;
