import classes from './TransferStack.module.scss'

const TransferStack = (props) => {

    const data = props.data

    return (
        <div className={classes.container}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Domain</th>
                        <th>Count</th>
                        <th>Amount USD</th>
                        <th>NIS Rate</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody className={classes.table_data}>
                    {data.map((item,index) => {
                        return(
                            <tr key={index}>
                                <td>{item.type}</td>
                                <td>{item.domain}</td>
                                <td>{item.count}</td>
                                <td>{item.amount}</td>
                                <td>{item.nisRate}</td>
                                <td>{item.added ? item.added : 'None'}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )

}

export default TransferStack