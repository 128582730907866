import classes from './CustomerWebsiteCard.module.scss'

import dollar from '../../../assets/icons/dollar.svg'
import shekel from '../../../assets/icons/shekel.svg'

import IconContainerMedium from '../../Customs/IconContainerMedium/IconContainerMedium'

import { useSelector } from 'react-redux';
import { fullYearParser } from '../../../utils/helper_functions';

const CustomerWebsiteCard = (props) => {

    const USD_Rate = useSelector(state => state.user.currentRate.rate)

    const names = useSelector(state => state.ui.language.values.websitesContent.customerWebsites)
    const name_code = useSelector(state => state.ui.language.code)
    const date = fullYearParser(props.ownersince*1000)
    let owner_string = `${names.owner_since} ${date}`

    if(name_code === 'HEB') owner_string = `${date} ${names.owner_since}`

    return (
        <div className={classes.container}>

            <div className={classes.top}>
               <span><a href={`https://${props.domain}`} target='_blank'>{props.domain}</a></span> 
            </div>

            <div className={classes.middle}>
                <div className={classes.middle_item}>
                    <IconContainerMedium image_src={shekel} alt='$'/>
                    <span>{props.earned.toFixed(2)}</span>
                </div>
                <div className={classes.middle_item}>
                    <IconContainerMedium image_src={dollar} alt='₪'/>
                    <span>{(props.earned/USD_Rate).toFixed(2)}</span>
                </div>
            </div>

            <div className={classes.bottom}>
                {owner_string}
            </div>

        </div>
    )
}

export default CustomerWebsiteCard