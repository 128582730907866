import classes from './FilterByDate.module.scss';

import CustomButton from '../Customs/CustomButton';
import PopUpCard from '../Cards/PopUpCard/PopUpCard';

import { useState } from 'react';

const FilterByDate = (props) => {

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const submitHandler = () => {

        props.setStart(startDate)
        props.setEnd(endDate)
        props.toggle()
        
    }

    return (
        <PopUpCard toggle={props.toggle} header={`${props.names.date_range}`}>
            <div className={classes.main_middle}>
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{`${props.names.start_date}`}</label>
                    <div className={classes.middle_item_input}>
                        <input className={classes.dateinput} required type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                    </div>
                </div>
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{`${props.names.end_date}`}</label>
                    <div className={classes.middle_item_input}>
                        <input className={classes.dateinput}required type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                    </div>
                </div>

                <div className={classes.buttons}>
                        <div className={classes.cancel} onClick={props.toggle}>{`${props.names.cancel}`}</div>
                        <div className={classes.customButton}><CustomButton reverse color='red' name={`${props.names.load}`} onClick={submitHandler}/></div>
                </div>
            </div>
        </PopUpCard>
    )

}

export default FilterByDate