import { createSlice } from '@reduxjs/toolkit'
import names from '../utils/names_langs'

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        language: {values: names.HEB, name:'hebrew', code:'HEB'},
        showChangeCurrency: false,
        showAdminInterface: false,
        isLoggedIn: false,
        minimizeNavBar: false,
        showNavBarMobile: false,
        isImpersonating: false,
        navigateToCustomers: false,
        disableExportButton: false,
        disableImportButton: false,
        showSystemMessages: true,
    },
    reducers: {
        toggleLanguage(state, action) {
            state.language = action.payload
        },
        setShowChangeCurrency(state,action) {
            state.showChangeCurrency = action.payload
        },
        setShowAdminInterface(state, action) {
            state.showAdminInterface = action.payload
        },
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload
        },
        setMinimizeNavBar(state, action) {
            state.minimizeNavBar = action.payload
        },
        setShowNavBarMobile(state, action) {
            state.showNavBarMobile = action.payload
        },
        setIsImpersonating(state, action) {
            state.isImpersonating = action.payload
        },
        setNavigateToCustomers(state, action) {
            state.navigateToCustomers = action.payload
        },
        setDisableExportButton(state, action) {
            state.disableExportButton = action.payload
        },
        setDisableImportButton(state, action) {
            state.disableImportButton = action.payload
        },
        setShowSystemMessages(state, action) {
            state.showSystemMessages = action.payload
        }
    }
})

export const uiActions = uiSlice.actions
export default uiSlice