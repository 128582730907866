import classes from './ResponsiveTable.module.scss'

import LoadingTable from '../../LoadingTable/LoadingTable'

import { useSelector } from 'react-redux';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { TableWidthHelper } from '../../../utils/helper_functions';

const ResponsiveTable = (props) => {

    let loadingResults = props.loadingResults
    const navbarminimized = useSelector(state => state.ui.minimizeNavBar)
    let {width} = useWindowSize()

    return (
        <div className={classes.container} style={(width < 1200 && width > 500) ? {width: TableWidthHelper(width,navbarminimized)} : {}}>
            {loadingResults && <LoadingTable />}
            {!loadingResults &&
            <table className={classes.table} cellSpacing={0}>
                {props.children}
            </table>
            }
        </div>
    )

}

export default ResponsiveTable;