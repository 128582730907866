import classes from './Pagination.module.scss'

import left from '../../assets/icons/arrow_left_red.svg'
import right from '../../assets/icons/arrow_right_red.svg'

import PaginationItem from '../PaginationItem/PaginationItem';

const Pagination = (props) => {

        let pageNumber = props.pageNumber
        let numberOfPages = props.numberOfPages

        let pagesToRender = [1,2,3,4]

        const goBackHandler = () => {
            if(pageNumber > 1) props.setPageNumber(pageNumber-1)   
        }

        const goFowardHandler = ()  => {
            if(pageNumber < numberOfPages) props.setPageNumber(pageNumber+1)   
        }

        const gotoPageHandler = (page) => {
            if(pageNumber !== page) props.setPageNumber(page)
        }

        if(numberOfPages < 4) {
            let number_of_pages_to_remove = pagesToRender.length - numberOfPages

            for(let i = 0; i<number_of_pages_to_remove; i++) {
                pagesToRender.pop()
            }

            return (
                <div className={classes.container}>
                <PaginationItem image_src={left} alt='go back' onClick={goBackHandler}/>
                {pagesToRender.map((page) => {
                    if(page === pageNumber) {
                        return <PaginationItem key={page} number={page} current onClick={() => gotoPageHandler(page)}/>
                    }
                    return <PaginationItem key={page} number={page} onClick={() => gotoPageHandler(page)}/>
                })}
                <PaginationItem image_src={right} alt='go foward' onClick={goFowardHandler}/>
                </div>    
            )
        }

        if(pageNumber === 2) {
            return (
                <div className={classes.container}>
                    <PaginationItem image_src={left} alt='go back' onClick={goBackHandler}/>
                    <PaginationItem key={1} number={1} onClick={() => gotoPageHandler(1)}/>
                    <PaginationItem key={2} number={2} current onClick={() => gotoPageHandler(2)}/>
                    <PaginationItem key={3} number={3}onClick={() => gotoPageHandler(3)}/>
                    <PaginationItem key={4} number={4} onClick={() => gotoPageHandler(4)}/>
                    <PaginationItem image_src={right} alt='go foward' onClick={goFowardHandler}/>
                </div>
            )
        }

        if(pageNumber === numberOfPages-1) {
            return (
                <div className={classes.container}>
                    <PaginationItem image_src={left} alt='go back' onClick={goBackHandler}/>
                    <PaginationItem key={numberOfPages-3} number={numberOfPages-3} onClick={() => gotoPageHandler(numberOfPages-3)}/>
                    <PaginationItem key={numberOfPages-2} number={numberOfPages-2} onClick={() => gotoPageHandler(numberOfPages-2)}/>
                    <PaginationItem key={numberOfPages-1} number={numberOfPages-1} current onClick={() => gotoPageHandler(numberOfPages-1)}/>
                    <PaginationItem key={numberOfPages} number={numberOfPages} onClick={() => gotoPageHandler(numberOfPages)}/>
                    <PaginationItem image_src={right} alt='go foward' onClick={goFowardHandler}/>
                </div>
            )
        }

        if(pageNumber === numberOfPages) {
            return (
                <div className={classes.container}>
                    <PaginationItem image_src={left} alt='go back' onClick={goBackHandler}/>
                    <PaginationItem key={numberOfPages-3} number={numberOfPages-3} onClick={() => gotoPageHandler(numberOfPages-3)}/>
                    <PaginationItem key={numberOfPages-2} number={numberOfPages-2} onClick={() => gotoPageHandler(numberOfPages-2)}/>
                    <PaginationItem key={numberOfPages-1} number={numberOfPages-1}  onClick={() => gotoPageHandler(numberOfPages-1)}/>
                    <PaginationItem key={numberOfPages} number={numberOfPages} current onClick={() => gotoPageHandler(numberOfPages)}/>
                    <PaginationItem image_src={right} alt='go foward' onClick={goFowardHandler}/>
                </div>
            )
        }

        // Normal Case where number of pages is bigger than 3 and we are not in the edges
        if(pageNumber > 2 && pageNumber < numberOfPages-1) {
            pagesToRender = [pageNumber-1,pageNumber,pageNumber+1,pageNumber+2]
        }

        return (
            <div className={classes.container}>
                <PaginationItem image_src={left} alt='go back' onClick={goBackHandler}/>
                {pagesToRender.map((page) => {
                    if(page === pageNumber) {
                        return <PaginationItem key={page} number={page} current onClick={() => gotoPageHandler(page)}/>
                    }
                    return <PaginationItem key={page} number={page} onClick={() => gotoPageHandler(page)}/>
                })}
                <PaginationItem image_src={right} alt='go foward' onClick={goFowardHandler}/>
            </div>
        )
}

export default Pagination;