import classes from './AddNewDomain.module.scss'

import globe from '../../assets/icons/globe_gray.svg'
import website from '../../assets/icons/website.svg'

import CustomButton from '../Customs/CustomButton'
import CustomSelect from '../Customs/CustomSelect/CustomSelect'
import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'

import { urlCleaner } from '../../utils/helper_functions'

import {useSelector} from 'react-redux'
import { useState } from 'react'

const AddNewDomain = (props) => {

    const names = useSelector(state => state.ui.language.values.websitesContent.AddNewDomain)
    const api_token = useSelector(state => state.user.token)

    const [domainValue, setDomainValue] = useState('')
    const [selectValue, setSelectValue] = useState('REGULAR')
    const [showSelectPlaceholder, setShowSelectPlaceholder] = useState(true)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    const selectHandler = (e) => {
        if(showSelectPlaceholder) setShowSelectPlaceholder(false)
        setSelectValue(e.target.value)

    }

    const submitHandler = async (e) => {
        e.preventDefault()

        let response = await fetch(`${API_URL}/api/v2/employees/websites`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                domain: urlCleaner(domainValue),
                type: selectValue
            })
        })

        let res_status = response.status
        response = response.status

        if(res_status === 200) setShowCheckAnimation(true)
        else {
            setShowFailAnimation(true)

            setTimeout(() => {
                setShowFailAnimation(false)
            }, 3000);
        }
        
    }

    return (
        <PopUpCard toggle={props.toggle} header={names.title}>
                {showCheckAnimation && <CheckAnimation />}
                {showFailAnimation && <FailAnimation />}
                <form className={classes.main_middle} onSubmit={submitHandler}>
                    <div className={classes.middle_item}>
                        <label className={classes.middle_item_label}>{names.domain_name}</label>
                        <div className={classes.middle_item_input}>
                            <img src={globe} alt='globe'/>
                            <input placeholder='domain.com' value={domainValue} onChange={(e) => setDomainValue(e.target.value)} required/>
                        </div>
                    </div>
                    <div className={classes.middle_item}>
                        <label className={classes.middle_item_label}>{names.select_website_type}</label>
                        <div className={[classes.middle_item_input,classes.remove_padding].join(' ')}>
                            <img src={website} alt='website'/>
                            <CustomSelect onChange={selectHandler} required value={selectValue}>
                                <option value='REGULAR'>{'Website'}</option>
                                <option value='PORTAL'>{'Portal'}</option>
                            </CustomSelect>
                            {/* {showSelectPlaceholder && <span onClick={() => setOpenMenu(true)}>Select portal or website</span>} */}
                        </div>
                    </div>

                    <div className={classes.buttons}>
                        <div className={classes.cancel} onClick={props.toggle}>{names.cancel}</div>
                        <div className={classes.customButton}><CustomButton reverse color='red' name={names.add} type='submit'/></div>
                    </div>
                </form>
        </PopUpCard>
    )

}

export default AddNewDomain