import classes from './BankDetails.module.scss';

import PopUpCard from '../Cards/PopUpCard/PopUpCard'
import CustomSelect from '../Customs/CustomSelect/CustomSelect';
import CustomButton from '../Customs/CustomButton';

import CheckAnimation from '../CheckAnimation/CheckAnimation';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../store/user-slice';
import { TimeOutHandler } from '../../utils/helper_functions';

const BankDetails = (props) => {

    const bankList = ['בנק יהב (4)',
    'בנק הדואר (9)'
    ,'בנק לאומי (10)'
    ,'בנק דיסקונט (11)'
    ,'בנק הפועלים (12)'
    ,'בנק אגוד (12)'
    ,'בנק אוצר החייל (14)'
    ,'בנק מרכנטיל דיסקונט (17)'
    ,'בנק מזרחי טפחות (20)'
    ,'הבנק הבינלאומי הראשון לישראל (31)'
    ,'בנק ערבי ישראל (34)'
    ,'בנק מסד (46)'
    ,'בנק פועלי אגודת ישראל (52)'
    ,'בנק ירושלים (54)']

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const update = props.update

    const paymentDetails = useSelector(state => state.user.paymentDetails)
    const filledPaymentDetails = useSelector(state => state.user.filledPaymentDetails)
    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL


    const [ownerName, setOwnerName] = useState(filledPaymentDetails ? paymentDetails.bank_account_owner : '')
    const [accountNumber, setAccountNumber] = useState(filledPaymentDetails ? paymentDetails.bank_account_number : '')
    const [bankBranch, setAccountBranch] = useState(filledPaymentDetails ? paymentDetails.bank_branch : '')
    const [bank, SetBank] = useState(filledPaymentDetails ? paymentDetails.bank : '')
    const [customBankInput, setCustomBankInput] = useState((filledPaymentDetails && !bankList.includes(paymentDetails.bank)) ? paymentDetails.bank : '')
    const [bankAddress, setBankAddress] = useState(filledPaymentDetails ? paymentDetails.bank_address : '')
    const [legalStatus, setLegalStatus] = useState(filledPaymentDetails ? paymentDetails.legal_status : 'Private')

    const [showCustomBankField, setShowCustomBankField] = useState(!bankList.includes(paymentDetails.bank) && paymentDetails.bank)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)

    const resetFieldsHandler = () => {
        setOwnerName('')
        setAccountNumber('')
        setAccountBranch('')
        SetBank(bankList[0])
        setBankAddress('')
        setLegalStatus('private')
        setShowCustomBankField(false)
        setCustomBankInput('')
    }

    const bankSelectHandler = (e) => {
        const value = e.target.value

        if(value === 'custom') {setShowCustomBankField(true)}
            
        else {
            if(showCustomBankField === true) {SetBank([bankList[0]]) }
            else {SetBank(value)} 
        }
    }

    const returnToDashboard = () => {
        navigate('/user/dashboard', { replace: true})
    }

    const submitHandler = async (e) => {
        e.preventDefault()

        const body = {
            bank_account_owner: ownerName,
            bank_account_number: accountNumber,
            bank:  bank,
            bank_branch: bankBranch,
            bank_address: bankAddress,
            legal_status: legalStatus
        }

        let response = await fetch(`${API_URL}/api/v2/customerBankDetailsController`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify(body)
        })

        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            dispatch(userActions.setPaymentsDetails(body))
            dispatch(userActions.setfilledPaymentDetails(true))
            setShowCheckAnimation(true)

            TimeOutHandler(() => {props.toggle()})
        }
        
    }

    return (
        <PopUpCard header={update ? 'עדכון פרטי חשבון בנק' : 'יש למלא באופן חד פעמי את פרטי חשבון הבנק'} toggle={update ? props.toggle : returnToDashboard}> 
        <div className={classes.container}>
            {showCheckAnimation && <CheckAnimation />}
            <form className={classes.main_middle} onSubmit={submitHandler}>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'שם המוטב'}</label>
                    <div className={classes.middle_item_input}>
                        <input placeholder='ישראל ישראלי' value={ownerName} onChange={((e) => setOwnerName(e.target.value))} required/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'מספר חשבון'}</label>
                    <div className={classes.middle_item_input}>
                        <input placeholder='987654321' type='number' value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} required/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'מספר סניף'}</label>
                    <div className={classes.middle_item_input}>
                        <input placeholder='987' type='number' value={bankBranch} onChange={(e) => setAccountBranch(e.target.value)} required/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'בנק'}</label>

                    {showCustomBankField &&
                    <div className={classes.middle_item_input}>
                        <input placeholder='שם בנק (מספר)' value={customBankInput} onChange={(e) => setCustomBankInput(e.target.value)} required/>
                    </div>                    
                    }
                    {!showCustomBankField &&
                    <div className={[classes.middle_item_input,classes.remove_padding].join(' ')}>
                        {/* <img src={globe} alt='globe'/> */}
                        <CustomSelect value={bank} onChange={bankSelectHandler} required>
                            {bankList.map((bank) => <option key={bank} value={bank}>{bank}</option>)}
                            <option key='custom' value='custom'>{'בנק אחר'}</option>
                        </CustomSelect>
                    </div>
                    }

                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'כתובת הסניף'}</label>
                    <div className={classes.middle_item_input}>
                        <input placeholder='רחוב הפעמונים 0, תל אביב' value={bankAddress} onChange={(e) => setBankAddress(e.target.value)} required/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'סטטוס מיסוי'}</label>
                    <div className={[classes.middle_item_input,classes.remove_padding].join(' ')}>
                        <CustomSelect value={legalStatus} onChange={(e) => setLegalStatus(e.target.value)}>
                            <option value='Private'>{'עוסק פטור'}</option>
                            <option value='Company'>{'עוסק מורשה/חברה בע"מ'}</option>
                        </CustomSelect>
                    </div>
                </div>


                <div className={classes.buttons}>
                    <div className={classes.customButton} onClick={update ? props.toggle : returnToDashboard}><CustomButton reverse color='red' name='חזרה אחורה'/></div>
                    <div className={classes.customButton} onClick={resetFieldsHandler}><CustomButton color='blue' name='אפס שדות'/></div>
                    <div className={classes.customButton}><CustomButton reverse color='blue' name='אשר' type='submit'/></div>
                </div>

            </form>
            </div>
        </PopUpCard>
    )

}

export default BankDetails;