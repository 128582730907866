import classes from './ViewCustomerTranscations.module.scss'

import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';

import { fullYearParser } from '../../utils/helper_functions';

const StackData = (props) => {

    const names = props.names
    const data = props.data

    return (
            <ResponsiveTable>
                <thead>
                        <tr>
                            <th>{names.domain}</th>
                            <th>{names.type}</th>
                            <th>{names.USD}</th>
                            <th>{names.NIS}</th>
                            <th>{names.nisRate}</th>
                            <th>{names.added}</th>
                            <th>{names.count}</th>
                            <th>{names.paid}</th>
                        </tr>
                    </thead>
                    <tbody className={classes.table_data}>
                        {data.map((item,index) => {
                            let added = item.added ? fullYearParser(item.added*1000) : ''
                            return (
                                <tr key={index}>
                                    <td style={{textTransform: 'none'}}>{`${item.domain}`}</td>
                                    <td>{`${item.type}`}</td>
                                    <td>{`${item.USD}`}</td>
                                    <td>{`${item.NIS}`}</td>
                                    <td>{`${item.nisRate}`}</td>
                                    <td>{`${added}`}</td>
                                    <td>{`${item.count}`}</td>
                                    <td className={item.paid ? classes.paid : classes.notpaid}>{item.paid ? 'Sent for payment' : 'Not Paid'}</td>
                                </tr>
                            )
                        })}
                    </tbody>
            </ResponsiveTable>
    )
}

export default StackData;