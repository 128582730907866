import classes from './CustomInput.module.scss'

import eye from '../../../assets/icons/eye.svg'

import { useState } from 'react'

const CustomInput = (props) => {

    const value = props.value
    const status = props.status

    const [type, setType] = useState(props.type ? props.type : 'text')

    let style = [classes.container].join(' ')
    const red = [classes.container,classes.red].join(' ')
    const blue = [classes.container,classes.blue].join(' ')
    const green = [classes.container,classes.green].join(' ')

    if(value.length > 0) {
        if(status === 'error') style = red;
        if(status === 'success') style = green;
        if(status === 'pending') style = blue;
    }

    const togglePasswordType = () => {
        if(type !== 'password') setType('password')
        else {
            setType('text')
        }
    }

    return (
        <div className={style}>
            <div className={classes.icon}><img src={props.image_src} alt=''/></div>
            <input placeholder={props.placeholder} name={props.name} type={type} onChange={props.onChange} value={value} required={props.required} disabled={props.disabled}/>
            {props.type === 'password' && <div className={classes.icon} style={{width: 22, height: 22, cursor: 'pointer'}}><img src={eye} alt='password_toggle' onClick={togglePasswordType}/></div>}
        </div>
    )
}

export default CustomInput