
const PaymentUserHeader = (props) => {

    const names = props.names

    return (
        <tr>
            <th>{names.status}</th>
            <th>{names.date}</th>
            <th>{'₪ ' + names.amount}</th>
            <th>{names.receipt}</th>
            {/* <th>{names.receipt_status}</th>
            <th>{names.receipt_tax}</th> */}
            <th>{names.tax_invoice}</th>
            <th>{names.approved}</th>
        </tr>
    )

}

export default PaymentUserHeader;