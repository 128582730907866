
const PaymentUserRequestHeader = (props) => {

    const names = props.names

    const onChangeHandler = (e) => {
        props.setCheckAllCheckboxes(val => !val)

        if(props.totalMoney.reduce((p,c) => p+c) !== 0) props.resetTotalMoney()
        if(props.totalTransfersList.length > 0) props.resetTransferList()
    }



    return (
        <tr>
            <th style={{width: '50px', minWidth: 'unset'}}><input type='checkbox' onChange={onChangeHandler}/></th>
            <th>{names.month}</th>
            <th>{names.year}</th>
            <th>{names.earnings}</th>
        </tr>
    )

}

export default PaymentUserRequestHeader;