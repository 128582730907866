import classes from './TransactionCalculatorItem.module.scss';

const TransactionsCalculatorItem = (props) => {

    if(props.data.length === 0) return <></>

    let sum = 0;
    for(let item of props.data) {
        sum += item.value
    }

    const SubItem = (props) => {
        console.log(props.data)
        return (
            <div className={classes.subitem}>
                <span>{props.data.domain}</span>
                <span>{props.data.date}</span>
                <span>{`₪ ${props.data.value.toFixed(2)}`}</span>
            </div>
        )
    }

    return (
        <div className={classes.container} >
            <div className={classes.general}>
                <input className={classes.checkbox} type='checkbox' onClick={() => props.updateTotal(sum)}/>
                <span className={classes.total}>{`₪ ${sum}`}</span>
            </div>
            <div>
                {props.data.map((item) => <SubItem key={item.value} data={item}/>)}
            </div>
        </div>
    )
}

export default TransactionsCalculatorItem;