
const HeadersOnAirHistory = (props) => {

    const names = props.names.campaigns.table

    return (
        <tr>
            <th>{names.start}</th>
            <th>{names.customer}</th>
            <th style={{textAlign: 'center'}}>{names.retainer}</th>
            <th>{names.additionalNote}</th>
            <th>{names.website}</th>
            <th>{names.added}</th>
            <th>{'(+)'}</th>
        </tr>
    )
}

export default HeadersOnAirHistory;