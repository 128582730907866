
import classes from './PortalsTableItem.module.scss'
import CustomSelect from "../../Customs/CustomSelect/CustomSelect"

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const PortalsTableItem = (props) => {

    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL
    const navigate = useNavigate()

    let data = props.data
    let portals_data_length = data.portals_data.length-1

    const [status,SetStatus] = useState(data.portals_data[portals_data_length].status)
    
    const updatePortalStatus = async (status) => {

    let response = await fetch(`${API_URL}/api/v2/users/websites/portals`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${api_token}`},
        body: JSON.stringify({
            id: data.id,
            portal: data.portal,
            status: status
        })
    })
    response = await response.json()

    }

    const portalChangeHandler = async (e) => {
        SetStatus(e.target.value)
        await updatePortalStatus(e.target.value)
    }  

    return (
        <tr>
        <td>{data.firstName}</td>
        <td>{data.lastName}</td>
        <td className={classes.portal_link}><span style={{cursor: 'pointer'}} onClick={() => navigate(`/user/customers?email=${data.email}`, {replace: true})}>{data.email}</span></td>
        <td style={{width: 150}}>
            <CustomSelect value={status} onChange={portalChangeHandler}>
                <option value={'CUSTOMER_ACTIVE'}>Customer Active</option>
                <option value={'COMPANY_ACTIVE'}>Company Active</option>
                <option value={'COMPANY_INACTIVE'}>Company Inactive</option>
                <option value={'ON_PAUSE'}>On Pause</option>
                <option value={'INACTIVE'}>Inactive</option>
                <option value={'PAYMENT_ISSUE'}>Payment Issue</option>
                <option value={'CANCELING'}>Canceling</option>
                <option value={'BLACK_LIST'}>Black List</option>
            </CustomSelect>
        </td>
        <td className={classes.portal_units}>
            <span style={{cursor: 'pointer'}} onClick={() => props.onStocksClick(data)}>{data.portals_data[portals_data_length].stocksAmount}</span>
        </td>
        <td className={classes.portal_link}>{data.portal}</td>
    </tr>
    )
}

export default PortalsTableItem