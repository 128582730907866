import classes from './UpdateStatus.module.scss'

import exit from '../../../assets/icons/exit.svg'

import PopUpCard from '../../Cards/PopUpCard/PopUpCard';
import CustomSelect from '../../Customs/CustomSelect/CustomSelect';
import CustomButton from '../../Customs/CustomButton';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { booleanParser } from '../../../utils/helper_functions';

const UpdateStatus = (props) => {

    const RECEIPT_REJECT_REASONS = ['הסכום אינו תואם','טקסט חופשי']
    const TAXINVOICE_REJECT_REASONS = ['קיים חוב במערכת - פנה למחלקת כספים','האתר אינו פעיל','טקסט חופשי']
    const CLIENT_CHECK_REASONS = ['טרם נשלחו קבלות או חשבוניות לחשבוניות עסקה קודמות','טקסט חופשי']

    const data = props.data
    const names = props.names

    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL

    // States that hold data
    const [receiptStatus, setRecepitStatus] = useState(data.receipt_status) //status
    const [receiptStatusRejectReason, setReceiptStatusRejectReason] = useState(RECEIPT_REJECT_REASONS[0]) // reject reason
    const [receiptStatusCustom, setReceiptStatusCustom] = useState('') // custom reject reason

    const [receiptTaxInvoice, setRecepitTaxInvoice] = useState(data.receipt_tax_invoice) //status
    const [taxInvoiceRejectReason, setTaxInvoiceRejectReason] = useState(TAXINVOICE_REJECT_REASONS[0]) // reject reason
    const [taxInvoiceCustom, setTaxInvoiceCustom] = useState('') // custom reject reason

    const [clientCheck, setClientCheck] = useState(data.client_check) //status
    const [clientCheckReason, setClientCheckReason] = useState(CLIENT_CHECK_REASONS[0]) // reject reason
    const [clientCheckCustom, setClientCheckCustom] = useState('') // custom reject reason


    // States that remove and add items to the dom
    const [showRejectReceiptStatus, setShowRejectReceiptStatus] = useState(false)
    const [showRejectReceiptInput, setshowRejectReceiptInput] = useState(false)

    const [showRejectTaxInvoice, setShowRejectTaxInvoice] = useState(false)
    const [showRejectTaxInvoiceInput, setShowRejectTaxInvoiceInput] = useState(false)

    const [showRejectClientCheck, setShowRejectClientCheck] = useState(false)
    const [showRejectClientCheckInput, setShowRejectClientCheckInput] = useState(false)

    useEffect(() => {

        const refuse_reason = data.refuse_reason

        if(!data.receipt_status) {
            if(RECEIPT_REJECT_REASONS.includes(refuse_reason.receipt_status_reason)) {
                setShowRejectReceiptStatus(true)
                setReceiptStatusRejectReason(refuse_reason.receipt_status_reason)
            }
            else if(refuse_reason.receipt_status_reason.length === 0){
                setRecepitStatus('None')
            }
            else {
                setShowRejectReceiptStatus(true)
                setshowRejectReceiptInput(true)
                setReceiptStatusCustom(refuse_reason.receipt_status_reason)
            }
        }

        if(!data.receipt_tax_invoice) {
            if(TAXINVOICE_REJECT_REASONS.includes(refuse_reason.receipt_tax_invoice_reason)) {
                setShowRejectTaxInvoice(true)
                setTaxInvoiceRejectReason(refuse_reason.receipt_tax_invoice_reason)
            }
            else if(refuse_reason.receipt_tax_invoice_reason.length === 0) {
                setRecepitTaxInvoice('None')
            }
            else {
                setShowRejectTaxInvoice(true)
                setShowRejectTaxInvoiceInput(true)
                setTaxInvoiceCustom(refuse_reason.receipt_tax_invoice_reason)
            }
        }

        if(!data.client_check) {
            if(CLIENT_CHECK_REASONS.includes(refuse_reason.client_check_reason)) {
                setShowRejectClientCheck(true)
                setClientCheckReason(refuse_reason.client_check_reason)
            }
            else if(refuse_reason.client_check_reason.length === 0) {
                setClientCheck('None')
            }
            else {
                setShowRejectClientCheck(true)
                setShowRejectClientCheckInput(true)
                setClientCheckCustom(refuse_reason.client_check_reason)
            }
        }
    },[])


    const submitHandler = async () => {

        const client_check = booleanParser(clientCheck) 
        const receipt_status = booleanParser(receiptStatus)
        const receipt_tax_invoice = booleanParser(receiptTaxInvoice)

        let response = await fetch(`${API_URL}/api/v2/paymentrequestcontroller`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                paymentRequestID: data.paymentRequestID,
                request_status: data.request_status,
                client_check: client_check,
                receipt_status: receipt_status,
                receipt_tax_invoice: receipt_tax_invoice,
                client_check_reason: (client_check || clientCheck === 'None') ? "" : (clientCheckCustom !== '' ? clientCheckCustom : clientCheckReason),
                receipt_status_reason: (receipt_status || receiptStatus === 'None') ? "" : (receiptStatusCustom !== '' ? receiptStatusCustom : receiptStatusRejectReason),
                receipt_tax_invoice_reason: (receipt_tax_invoice || receiptTaxInvoice === 'None') ? "" : (taxInvoiceCustom !== '' ? taxInvoiceCustom : taxInvoiceRejectReason),
            })
        })

        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            props.forceUpdate()
            props.toggle()
        }
    }

    
    // Those Functions Handle the Main Selector (Approve,Reject)
    const receiptStatusSelectHandler = (e) => {
        const value = e.target.value

        if(value === 'false') {setShowRejectReceiptStatus(true)}
        else {
            setShowRejectReceiptStatus(false)
            setshowRejectReceiptInput(false)
            setReceiptStatusRejectReason(RECEIPT_REJECT_REASONS[0])
        }
            
        setRecepitStatus(value)
    }

    const taxInvoiceStatusSelectHandler = (e) => {
        const value = e.target.value

        if(value === 'false') {setShowRejectTaxInvoice(true)}
        else {
            setShowRejectTaxInvoice(false)
            setShowRejectTaxInvoiceInput(false)
            setTaxInvoiceRejectReason(TAXINVOICE_REJECT_REASONS[0])
            }
            
        setRecepitTaxInvoice(value)
    }

    const clientCheckStatusSelectHandler = (e) => {
        const value = e.target.value

        if(value === 'false') {
            setShowRejectClientCheck(true)
        }
     
        else {
            setShowRejectClientCheck(false)
            setShowRejectClientCheckInput(false)
            setClientCheckReason(CLIENT_CHECK_REASONS[0])
        }

        setClientCheck(value)
    }


    // Those Three Functions Handle the Inner Reject Select
    const receiptRejectSelectHandler = (e) => {
        const value = e.target.value;

        if(value === 'טקסט חופשי') {setshowRejectReceiptInput(true)}

        else {
            if(showRejectReceiptInput) {
                setshowRejectReceiptInput(false)
            }
        }

        setTaxInvoiceRejectReason(value)
    }

    const taxInvoiceRejectSelectHandler = (e) => {
        const value = e.target.value;

        if(value === 'טקסט חופשי') {setShowRejectTaxInvoiceInput(true)}

        else {
            if(showRejectReceiptInput) {
                setShowRejectTaxInvoiceInput(false)
            }
        }
        setTaxInvoiceRejectReason(value)
    }

    const clientCheckRejectSelectHandler = (e) => {
        const value = e.target.value;

        if(value === 'טקסט חופשי') {setShowRejectClientCheckInput(true)}

        else {
            if(showRejectReceiptInput) {
                setShowRejectClientCheckInput(false)
            }
        }

        setClientCheckReason(value)
    }

    return (
        <PopUpCard header={`${names.updateStatus.title} ${data.customer.firstName} ${data.customer.lastName}`} toggle={props.toggle}>
            <form className={classes.main_middle}>
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.receipt_status}</label>
                    <div className={[classes.middle_item_input,classes.remove_padding].join(' ')}>
                        <CustomSelect value={receiptStatus} onChange={receiptStatusSelectHandler}>
                            <option value={'true'}>{names.updateStatus.approve}</option>
                            <option value={'false'}>{names.updateStatus.reject}</option>
                            <option value={'None'}>{names.updateStatus.none}</option>
                        </CustomSelect>
                        {showRejectReceiptStatus &&
                        <div>
                            {!showRejectReceiptInput &&
                            <CustomSelect value={receiptStatusRejectReason} onChange={receiptRejectSelectHandler}>
                                {RECEIPT_REJECT_REASONS.map((item) => <option key={item} value={item}>{item}</option>)}
                            </CustomSelect>
                            }
                            {showRejectReceiptInput &&
                            <div style={{display: 'flex'}}>
                                <img src={exit} alt='X' onClick={() => {setshowRejectReceiptInput(false);setReceiptStatusCustom('')}}/>
                                <input placeholder='טקסט חופשי' value={receiptStatusCustom} onChange={(e) => setReceiptStatusCustom(e.target.value)}/>
                            </div>
                            }
                        </div>                        
                        }
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.client_check}</label>
                    <div className={[classes.middle_item_input,classes.remove_padding].join(' ')}>
                        <CustomSelect value={clientCheck} onChange={clientCheckStatusSelectHandler}>
                            <option value={'true'}>{names.updateStatus.approve}</option>
                            <option value={'false'}>{names.updateStatus.reject}</option>
                            <option value={'None'}>{names.updateStatus.none}</option>
                        </CustomSelect>
                        {showRejectClientCheck &&
                        <div>
                            {!showRejectClientCheckInput &&
                            <CustomSelect value={clientCheckReason} onChange={clientCheckRejectSelectHandler}>
                                {CLIENT_CHECK_REASONS.map((item) => <option key={item} value={item}>{item}</option>)}
                            </CustomSelect>                            
                            }
                            {showRejectClientCheckInput &&
                            <div style={{display: 'flex'}}>
                                 <img src={exit} alt='X' onClick={() => {setShowRejectClientCheckInput(false);setClientCheckCustom('')}}/>
                                <input placeholder='טקסט חופשי' value={clientCheckCustom} onChange={(e) => setClientCheckCustom(e.target.value)}/>
                            </div>
                            }
                        </div>                        
                        }
                    </div>
                </div>

                {data.invoice_img &&
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.receipt_tax}</label>
                    <div className={[classes.middle_item_input,classes.remove_padding].join(' ')}>
                        <CustomSelect value={receiptTaxInvoice} onChange={taxInvoiceStatusSelectHandler}>
                            <option value={'true'}>{names.updateStatus.approve}</option>
                            <option value={'false'}>{names.updateStatus.reject}</option>
                            <option value={'None'}>{names.updateStatus.none}</option>
                        </CustomSelect>
                        {showRejectTaxInvoice &&
                        <div>
                            {!showRejectTaxInvoiceInput &&
                            <CustomSelect value={taxInvoiceRejectReason} onChange={taxInvoiceRejectSelectHandler}>
                                {TAXINVOICE_REJECT_REASONS.map((item) => <option key={item} value={item}>{item}</option>)}
                             </CustomSelect>
                            }
                            {showRejectTaxInvoiceInput && 
                            <div style={{display: 'flex'}}>
                                <img src={exit} alt='X' onClick={() => {setShowRejectTaxInvoiceInput(false);setTaxInvoiceCustom('')}}/>
                                <input placeholder='טקסט חופשי' value={taxInvoiceCustom} onChange={(e) => setTaxInvoiceCustom(e.target.value)}/>
                            </div>}
                        </div>                        
                        }
                    </div>
                </div>                
                }

                <div className={classes.buttons}>
                    <div className={classes.cancel} onClick={props.toggle}>{names.updateStatus.cancel}</div>
                    <div className={classes.customButton}><CustomButton reverse color='red' name={names.updateStatus.submit} onClick={submitHandler}/></div>
                </div>

            </form>
        </PopUpCard>
    )

}

export default UpdateStatus;